var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showSelectFolderDialog, width: "450px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Select Folder")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("select-folder-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-wrap align-center gap pa-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap align-center gap cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.navigateToFolder(undefined)
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { color: "#3F51B5" }
                    },
                    [_vm._v(" " + _vm._s(_vm.mdiHome) + " ")]
                  ),
                  _vm.splitPath.length > 0
                    ? _c(
                        "div",
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiChevronRight) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.splitPath, function(p, index) {
                return _c(
                  "div",
                  {
                    key: index + "-" + p,
                    staticClass: "d-flex flex-wrap align-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "breadcrumb-text mt-1 cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.navigateToFolder(index)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(p) + " ")]
                    ),
                    index !== _vm.splitPath.length - 1
                      ? _c(
                          "div",
                          [
                            _c("v-icon", [
                              _vm._v(" " + _vm._s(_vm.mdiChevronRight) + " ")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "v-list",
            _vm._l(_vm.foldersAtLevel, function(f) {
              return _c(
                "v-list-item",
                { key: f.folder, staticClass: "mx-0 px-0 cursor-pointer" },
                [
                  _c(
                    "v-list-item-content",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onFolderClick(f.folder)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "d-flex align-center px-4" },
                        [
                          _c("v-icon", { staticClass: "mr-3" }, [
                            _vm._v(" " + _vm._s(_vm.mdiFolder) + " ")
                          ]),
                          _vm._v(
                            " " + _vm._s(f.folder.split("/").slice(-1)[0]) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", text: "" },
                  on: {
                    click: function($event) {
                      _vm.showCreateFolderDialog = true
                    }
                  }
                },
                [_vm._v(" Create Folder ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", dark: "" },
                  on: { click: _vm.selectFolder }
                },
                [_vm._v("Select Folder")]
              )
            ],
            1
          ),
          _vm.showCreateFolderDialog
            ? _c("CreateFolderDialog", {
                attrs: { showCreateFolderDialog: _vm.showCreateFolderDialog },
                on: {
                  "create-folder-dialog-close": function($event) {
                    _vm.showCreateFolderDialog = false
                  },
                  "create-folder": _vm.onCreateFolder
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }