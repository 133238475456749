<template>
  <v-dialog :value="showSelectFolderDialog" width="450px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Select Folder</div>
        <v-spacer />
        <v-btn icon @click="$emit('select-folder-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="d-flex flex-wrap align-center gap pa-3">
        <div
          class="d-flex flex-wrap align-center gap cursor-pointer"
          @click="navigateToFolder(undefined)"
        >
          <v-icon color="#3F51B5" class="cursor-pointer">
            {{ mdiHome }}
          </v-icon>
          <div v-if="splitPath.length > 0">
            <v-icon>
              {{ mdiChevronRight }}
            </v-icon>
          </div>
        </div>
        <div
          class="d-flex flex-wrap align-center"
          v-for="(p, index) of splitPath"
          :key="`${index}-${p}`"
        >
          <div
            class="breadcrumb-text mt-1 cursor-pointer"
            @click="navigateToFolder(index)"
          >
            {{ p }}
          </div>
          <div v-if="index !== splitPath.length - 1">
            <v-icon>
              {{ mdiChevronRight }}
            </v-icon>
          </div>
        </div>
      </div>

      <v-list>
        <v-list-item
          v-for="f of foldersAtLevel"
          :key="f.folder"
          class="mx-0 px-0 cursor-pointer"
        >
          <v-list-item-content @click="onFolderClick(f.folder)">
            <v-list-item-title class="d-flex align-center px-4">
              <v-icon class="mr-3">
                {{ mdiFolder }}
              </v-icon>
              {{ f.folder.split("/").slice(-1)[0] }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="d-flex justify-end px-5 py-3">
        <v-btn color="#3F51B5" text @click="showCreateFolderDialog = true">
          Create Folder
        </v-btn>
        <v-btn color="#3F51B5" dark @click="selectFolder">Select Folder</v-btn>
      </div>

      <CreateFolderDialog
        v-if="showCreateFolderDialog"
        :showCreateFolderDialog="showCreateFolderDialog"
        @create-folder-dialog-close="showCreateFolderDialog = false"
        @create-folder="onCreateFolder"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiFolder, mdiChevronRight, mdiHome } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import CreateFolderDialog from "@/components/mapView/docs-tab/add-files-dialog/folder-path-display/select-folder-dialog/CreateFolderDialog.vue";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SelectFolderDialog",
  data() {
    return {
      mdiClose,
      mdiFolder,
      mdiChevronRight,
      mdiHome,
      folders: [],
      level: 1,
      selectedFolderPath: undefined,
      showCreateFolderDialog: false,
    };
  },
  components: {
    CreateFolderDialog,
  },
  props: {
    showSelectFolderDialog: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
  },
  computed: {
    allFolders() {
      const folders = this.folders
        ?.map((f) => {
          const splitPath = f.folder.split("/");
          const subfolders = Array(splitPath.length)
            .fill()
            .map((_, i) => splitPath.slice(0, i + 1).join("/"));
          return [...subfolders];
        })
        .flat()
        .filter((f) => Boolean(f) && !f.includes("undefined"));
      const foldersWithoutDups = [...new Set(folders)];
      return foldersWithoutDups.map((folderName) => {
        const folder = this.folders?.find((fs) =>
          fs.folder.includes(folderName)
        );
        return {
          folder: folderName,
          cover_image_url: folder?.cover_image_url,
        };
      });
    },
    foldersAtLevel() {
      return this.allFolders?.filter(
        (f) => f?.folder?.split("/")?.length === this.level
      );
    },
    splitPath() {
      return this.selectedFolderPath?.split("/") ?? [];
    },
  },
  methods: {
    onCreateFolder(folderName) {
      this.showCreateFolderDialog = false;
      const newFolder = `${this.selectedFolderPath ?? ""}${
        this.selectedFolderPath ? "/" : ""
      }${folderName}`;
      this.folders.push({
        folder: newFolder,
        cover_image_url: null,
      });
      this.selectedFolderPath = newFolder;
      this.$emit("select-folder", this.selectedFolderPath);
    },
    selectFolder() {
      this.$emit("select-folder", this.selectedFolderPath);
    },
    navigateToFolder(index) {
      if (typeof index === "undefined") {
        this.selectedFolderPath = undefined;
        this.level = 1;
        return;
      }
      this.selectedFolderPath = this.selectedFolderPath
        ?.split("/")
        ?.slice(0, index + 1)
        ?.join("/");
      this.level = index + 2;
    },
    async getFolders() {
      const { objectId, globalId, selectedMapServiceId } = this;
      if (!selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/map_services/${selectedMapServiceId}/features/${globalId}/folders`,
        {
          params: {
            object_id: objectId,
          },
        }
      );
      this.folders = results;
    },
    onFolderClick(folderName) {
      this.level++;
      this.selectedFolderPath = folderName;
    },
  },
  beforeMount() {
    this.getFolders();
  },
};
</script>
