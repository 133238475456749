<template>
  <div>
    <div class="d-flex flex-wrap align-center gap">
      <div class="d-flex flex-wrap align-center gap">
        <v-icon color="#3F51B5" class="cursor-pointer">
          {{ mdiHome }}
        </v-icon>
        <div v-if="splitPath.length > 1">
          <v-icon>
            {{ mdiChevronRight }}
          </v-icon>
        </div>
      </div>
      <div
        class="d-flex flex-wrap align-center"
        v-for="(p, index) of splitPath"
        :key="`${index}-${p}`"
      >
        <div class="mt-1">
          {{ p }}
        </div>
        <div v-if="index !== splitPath.length - 1">
          <v-icon>
            {{ mdiChevronRight }}
          </v-icon>
        </div>
      </div>
      <v-icon
        color="#3F51B5"
        class="cursor-pointer"
        @click="showSelectFolderDialog = true"
      >
        {{ mdiFolderMove }}
      </v-icon>
    </div>

    <SelectFolderDialog
      :showSelectFolderDialog="showSelectFolderDialog"
      :globalId="globalId"
      :objectId="objectId"
      :selectedMapServiceId="selectedMapServiceId"
      @select-folder-dialog-close="showSelectFolderDialog = false"
      @select-folder="
        folderPath = $event;
        $emit('select-folder', $event);
        showSelectFolderDialog = false;
      "
    />
  </div>
</template>

<script>
import { mdiHome, mdiChevronRight, mdiFolderMove } from "@mdi/js";
import SelectFolderDialog from "@/components/mapView/docs-tab/add-files-dialog/folder-path-display/SelectFolderDialog.vue";

export default {
  name: "FolderPathDisplay",
  components: {
    SelectFolderDialog,
  },
  data() {
    return {
      mdiHome,
      mdiChevronRight,
      mdiFolderMove,
      showSelectFolderDialog: false,
      folderPath: "",
    };
  },
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    selectedFolder: String,
  },
  computed: {
    splitPath() {
      return this.folderPath?.split("/") ?? [];
    },
  },
  beforeMount() {
    if (this.selectedFolder) {
      this.folderPath = this.selectedFolder;
    }
  },
  watch: {
    selectedFolder(val) {
      if (val) {
        this.folderPath = val;
      }
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
