<template>
  <v-dialog v-model="showApplyEditResultDialog" max-width="500px" persistent>
    <v-card>
      <v-card-title> Error updating GIS field </v-card-title>

      <v-card-text class="px-6 py-2"> {{ errorMessage }}</v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn @click="$emit('close')" text>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ApplyEditResultDialog",
  props: {
    showApplyEditResultDialog: Boolean,
    errorMessage: String,
  },
};
</script>
