var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loadingLogEntries
        ? _c("div", { staticClass: "px-3" }, [
            !_vm.isOnline
              ? _c(
                  "div",
                  { staticClass: "d-flex pa-0 ma-0" },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v(" " + _vm._s(_vm.mdiCloudOffOutline) + " ")
                    ]),
                    _c("div", { staticClass: "caption" }, [
                      _vm._v(" Offline. Displaying only forms saved on device ")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "py-0 my-0" },
              [
                _vm.formResultGroups.length > 0
                  ? _c("div", [
                      _c(
                        "section",
                        {
                          class: _vm.inExpandInfoDialog
                            ? "overflow-y-auto"
                            : undefined,
                          style: _vm.contentHeight
                        },
                        [
                          _vm._l(_vm.formResultGroups, function(
                            formResultGroup
                          ) {
                            return _c(
                              "div",
                              { key: formResultGroup.date },
                              [
                                formResultGroup.date !== "null"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-4 d-flex align-center gap"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-medium display-1 black--text"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f(
                                                    "formatVariableDueDate"
                                                  )(formResultGroup.date, "DD")
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("section", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "black--text",
                                              staticStyle: {
                                                "font-size": "20px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatVariableDueDate"
                                                    )(
                                                      formResultGroup.date,
                                                      "dddd"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "black--text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatVariableDueDate"
                                                    )(
                                                      formResultGroup.date,
                                                      "MMMM YYYY"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-timeline",
                                  {
                                    staticClass: "ml-n3",
                                    attrs: { dense: "", "align-top": "" }
                                  },
                                  _vm._l(
                                    formResultGroup.groupedFormResults,
                                    function(formResult, i) {
                                      return _c(
                                        "v-timeline-item",
                                        {
                                          key:
                                            formResult.form_result_id +
                                            "-" +
                                            formResult.type +
                                            "-" +
                                            i,
                                          attrs: {
                                            color: _vm.isSubmitFinal(formResult)
                                              ? "#3F51B5"
                                              : "#00A9F4",
                                            "fill-dot": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                slot: "icon",
                                                id: "formButton"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onClipboardClick(
                                                    formResult
                                                  )
                                                }
                                              },
                                              slot: "icon"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    size: "24"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiClipboard) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "caption font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("time")(
                                                        formResult.updatedOn
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      formResult.title ||
                                                        _vm.getFormName(
                                                          formResult.computed_form_definition
                                                        )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("div", [
                                              formResult.type === "offline"
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "py-1" },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            attrs: {
                                                              color: "#e0e0e0",
                                                              disabled: true
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.isSubmitFinal(
                                                                    formResult
                                                                  )
                                                                    ? "Final"
                                                                    : "Draft"
                                                                ) +
                                                                ". Saved on device. "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                : _vm._e(),
                                              formResult.type === "online"
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "py-1" },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            attrs: {
                                                              color: "#e0e0e0",
                                                              disabled: true
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.isSubmitFinal(
                                                                    formResult
                                                                  )
                                                                    ? "Final"
                                                                    : "Draft"
                                                                ) +
                                                                ". Saved online. "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _vm.loadingMoreLogEntries
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      size: "16",
                                      color: "#3F51B5"
                                    }
                                  })
                                : _vm._e(),
                              _vm.formResultGroups.length > 0 &&
                              _vm.totalResultCount > _vm.currentTotalResultCount
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        color: "#3F51B5",
                                        disabled: _vm.loadingMoreLogEntries
                                      },
                                      on: { click: _vm.showMoreEntries }
                                    },
                                    [_vm._v(" Show More ")]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "py-3",
                                      staticStyle: { opacity: "0.6" }
                                    },
                                    [_vm._v(" All Form Submissions Loaded ")]
                                  )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ])
                  : _c(
                      "v-card",
                      { staticClass: "elevation-0", attrs: { width: "100%" } },
                      [
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "elevation-0 d-flex justify-center items-center"
                          },
                          [
                            _vm._v(
                              " There are no form submissions for this site yet. "
                            )
                          ]
                        )
                      ],
                      1
                    )
              ],
              1
            )
          ])
        : _c(
            "div",
            { staticClass: "d-flex items-center justify-center py-16" },
            [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
            1
          ),
      _c("UnsavedChangesDialog", {
        attrs: {
          showUnsavedChangesWarningDialog: _vm.showUnsavedChangesWarningDialog
        },
        on: {
          "close-and-lose-changes": _vm.onCloseAndLoseChanges,
          "return-to-form": function($event) {
            _vm.showUnsavedChangesWarningDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }