<template>
  <div class="py-1" :class="{ background: inRightPane }">
    <section class="px-7 py-3 d-flex justify-space-between align-center">
      <div :class="{ background: inRightPane }">
        <section v-if="mapServicesOfNonLocateRequestChoices.length > 1">
          <v-select
            label="Layer"
            :items="mapServicesOfNonLocateRequestChoices"
            item-value="value"
            item-text="name"
            name="layer"
            v-model="selectedLayer"
            @change="$emit('layer-selected', selectedLayer)"
            :style="{
              'max-width':
                $vuetify.breakpoint.mdAndUp && inExpandInfoDialog
                  ? undefined
                  : '200px',
              width:
                $vuetify.breakpoint.mdAndUp && inExpandInfoDialog
                  ? undefined
                  : '200px',
            }"
          />
        </section>

        <section>
          <v-select
            v-model="formDefinitionId"
            :items="formDefinitionsOfSelectedMapServiceChoices"
            label="Report Form"
            hide-details="auto"
            name="formDef"
            item-value="value"
            item-text="name"
            :disabled="!selectedLayer"
            :style="{
              'max-width':
                $vuetify.breakpoint.mdAndUp && inExpandInfoDialog
                  ? undefined
                  : '200px',
              width:
                $vuetify.breakpoint.mdAndUp && inExpandInfoDialog
                  ? undefined
                  : '200px',
            }"
            id="formDropdown"
          >
            <template #no-data>
              <div class="px-5 py-3">
                No report forms configured for this layer
              </div>
            </template>

            <template #selection="{ item }">
              <div class="drop-down-selection">
                {{ item.name }}
              </div>
            </template>
          </v-select>
        </section>
      </div>

      <div :class="{ background: inRightPane }" class="pl-4">
        <v-btn
          color="#3F51B5"
          @click="onFabClick"
          :disabled="!formDefinitionId"
          :dark="Boolean(formDefinitionId)"
          fab
          small
          id="startFormBtn"
        >
          <v-icon>
            {{ mdiNotePlusOutline }}
          </v-icon>
        </v-btn>
      </div>
    </section>

    <v-dialog
      v-model="showEditDialog"
      max-width="600"
      close
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <TicketEditForm
          v-if="showEditDialog"
          :selectedGisInfo="selectedGisInfoObj"
          :selectedMapServiceId="selectedMapServiceId"
          :formDefinitionId="formDefinitionId"
          :globalId="globalId"
          :objectId="objectId"
          @ticket-edit-form-close-button-click="
            onTicketEditFormCloseButtonClick
          "
          @ticket-edit-form-close="
            showEditDialog = false;
            $emit('ticket-edit-form-close');
          "
          @ticket-edit-form-submitted="
            showEditDialog = false;
            $emit('ticket-edit-form-submitted');
          "
          @ticket-edit-form-error="
            showErrorDialog = true;
            errMsg = $event;
          "
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" max-width="600px" close>
      <v-card>
        <v-card-title> Error </v-card-title>
        <v-card-text>
          {{ errMsg }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <ViewOnlyUserDialog
      :showViewOnlyUserDialog="showViewOnlyUserDialog"
      @view-only-dialog-close="showViewOnlyUserDialog = false"
    />

    <UnsavedChangesDialog
      :showUnsavedChangesWarningDialog="showUnsavedChangesWarningDialog"
      @close-and-lose-changes="onCloseAndLoseChanges"
      @return-to-form="showUnsavedChangesWarningDialog = false"
    />

    <ChooseTaskDialog
      v-if="showChooseTaskDialog"
      :showChooseTaskDialog="showChooseTaskDialog"
      :selectedMapServiceId="selectedMapServiceId"
      :objectId="objectId"
      :globalId="globalId"
      :formDefinitionId="formDefinitionId"
      @choose-task-dialog-close="showChooseTaskDialog = false"
      @task-selected="onTaskSelected"
      @start-new-form="onStartNewForm"
    />
  </div>
</template>

<script>
import TicketEditForm from "@/components/tickets/TicketEditForm.vue";
import { mdiNotePlusOutline } from "@mdi/js";
import { db } from "@/mixins/utilisync-db";
import ViewOnlyUserDialog from "@/components/tickets/ticket-form-def-dropdown/ViewOnlyUserDialog";
import UnsavedChangesDialog from "@/components/tickets/ticket-log/UnsavedChangesDialog";
import ChooseTaskDialog from "@/components/tickets/ticket-form-def-dropdown/ChooseTaskDialog";
import { mapGetters, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "TicketFormDefDropdown",
  props: {
    selectedGisInfoObj: Object,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    inRightPane: Boolean,
    inExpandInfoDialog: Boolean,
  },
  async beforeMount() {
    this.formDefinitionId = localStorage.getItem("selected-form-definition-id");
    await this.getMapServices();
  },
  components: {
    TicketEditForm,
    ViewOnlyUserDialog,
    UnsavedChangesDialog,
    ChooseTaskDialog,
  },
  computed: {
    selectedGisInfo() {
      return this.selectedGisInfoObj;
    },
    mapServicesOfNonLocateRequestChoices() {
      const { selectedMapServiceId, globalId } = this;
      return [{ value: selectedMapServiceId, name: globalId }];
    },
    formDefinitionsOfSelectedMapServiceChoices() {
      if (!Array.isArray(this.formDefinitionsOfSelectedMapService)) {
        return [];
      }
      return this.formDefinitionsOfSelectedMapService
        .filter((f) => {
          return f.organization_user_enabled;
        })
        .map(({ form_definition_id: formDefinitionId, title }) => {
          return {
            value: formDefinitionId,
            name: title,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    ...mapGetters(["formSubmitted"]),
  },
  data() {
    return {
      formDefinitionId: "",
      showEditDialog: false,
      formDefinitionsOfSelectedMapService: [],
      mapServicesOfNonLocateRequest: [],
      selectedLayer: undefined,
      mdiNotePlusOutline,
      showErrorDialog: false,
      errMsg: "",
      showViewOnlyUserDialog: false,
      showUnsavedChangesWarningDialog: false,
      showChooseTaskDialog: false,
      showTicketEditForm: false,
      selectedTask: {},
    };
  },
  methods: {
    ...mapMutations(["setDynamicFormProps"]),
    async onStartNewForm() {
      this.showChooseTaskDialog = false;
      const {
        existingFormResultIdMap,
        globalId,
        objectId,
        selectedMapServiceId,
        formDefinitionId,
      } = this;
      const [formDefinition] = await db.formDefinitions
        .filter((f) => f.form_definition_id === formDefinitionId)
        .toArray();
      const dynamicFormProps = {
        formDefinition,
        existingFormResultIdMap,
        canEdit: true,
        globalId,
        objectId,
        selectedMapServiceId,
      };
      this.setDynamicFormProps(dynamicFormProps);
    },
    async onTaskSelected(selectedTask) {
      this.selectedTask = selectedTask;
      const { form_definition_id: formDefinitionId, item_id: taskId } =
        selectedTask;
      this.showChooseTaskDialog = false;
      const { globalId, objectId, selectedMapServiceId } = this;
      const [formDefinition] = await db.formDefinitions
        .filter((f) => f.form_definition_id === formDefinitionId)
        .toArray();
      const dynamicFormProps = {
        formDefinition,
        existingFormResultIdMap: {
          formResultId: uuidv4(),
        },
        canEdit: true,
        globalId,
        objectId,
        selectedMapServiceId,
        taskId,
      };
      this.setDynamicFormProps(dynamicFormProps);
    },
    onCloseAndLoseChanges() {
      this.showUnsavedChangesWarningDialog = false;
      this.showEditDialog = false;
    },
    onTicketEditFormCloseButtonClick(hasUnsavedChanges) {
      if (hasUnsavedChanges) {
        this.showUnsavedChangesWarningDialog = true;
      } else {
        this.showEditDialog = false;
      }
    },
    async getMapServices() {
      this.mapServicesOfNonLocateRequest = await db.mapServices
        .toCollection()
        .toArray();
    },
    async onFabClick() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth?.is_view_only_user) {
        this.showViewOnlyUserDialog = true;
        return;
      }

      const { selectedMapServiceId, objectId, formDefinitionId } = this;
      let openTasksWithFormDefId = [];
      if (navigator.onLine) {
        const tasks = await db.tasks.toCollection().toArray();
        openTasksWithFormDefId = tasks.filter(
          (r) =>
            r.map_service_id === selectedMapServiceId &&
            +r.feature_id === +objectId &&
            r.form_definition_id === formDefinitionId &&
            r.status.toLowerCase() !== "closed" &&
            r.status.toLowerCase() !== "canceled"
        );
      }
      if (openTasksWithFormDefId.length === 0) {
        const [formDefinition] = await db.formDefinitions
          .filter((f) => f.form_definition_id === formDefinitionId)
          .toArray();
        const { globalId, objectId, selectedMapServiceId } = this;
        const dynamicFormProps = {
          formDefinition,
          existingFormResultIdMap: {
            formResultId: uuidv4(),
          },
          canEdit: true,
          globalId,
          objectId,
          selectedMapServiceId,
        };
        this.setDynamicFormProps(dynamicFormProps);
      } else {
        this.showChooseTaskDialog = true;
      }

      localStorage.setItem(
        "selected-form-definition-id",
        this.formDefinitionId
      );
      this.$emit("show-edit-dialog");
    },
  },
  watch: {
    formSubmitted: {
      deep: true,
      handler(val) {
        if (val?.isFormSubmitted && val?.formResultId) {
          this.$emit("ticket-edit-form-submitted");
        }
      },
    },
    selectedLayer: {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        const [results] = await db.mapServices
          .filter((m) => m.map_service_id === val)
          .toArray();
        if (!Array.isArray(results?.forms)) {
          return;
        }
        this.formDefinitionsOfSelectedMapService = [...results.forms];
      },
    },
    mapServicesOfNonLocateRequestChoices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length === 1) {
          const [selectedLayer] = val;
          this.selectedLayer = selectedLayer.value;
        }
      },
    },
  },
};
</script>

<style>
.drop-down-selection {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
