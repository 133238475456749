<template id="tasksPanel">
  <div>
    <div v-if="loadingTasks" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-else>
      <div class="d-flex justify-end align-center">
        <v-btn
          text
          color="#3F51B5"
          class="d-flex ma-0 pa-0 px-2"
          @click="refreshTasks()"
        >
          <v-icon small class="mr-1">
            {{ mdiRefresh }}
          </v-icon>
          Reload
        </v-btn>

        <v-btn
          text
          color="#3F51B5"
          @click="showAddTaskDialog = true"
          class="d-flex ma-0 pa-0 px-2"
          id="createTaskBtn"
        >
          <v-icon small class="mr-1">
            {{ mdiPlus }}
          </v-icon>
          Schedule Form
        </v-btn>
      </div>

      <section
        class="overflow-y-auto"
        :style="{
          height:
            $vuetify.breakpoint.mdAndUp && inExpandInfoDialog
              ? `${tasksTabHeight}px`
              : undefined,
        }"
      >
        <div v-if="taskGroups.length > 0">
          <div v-for="taskGroup of taskGroups" :key="taskGroup.date">
            <v-card light flat class="ml-n3" v-if="taskGroup.date !== 'null'">
              <v-container class="pa-0">
                <v-layout align-center>
                  <v-layout justify-center style="max-width: 95px">
                    <div class="display-1 font-weight-medium">
                      {{ taskGroup.date | formatVariableDueDate("D") }}
                    </div>
                  </v-layout>
                  <v-layout column class="ml-n2">
                    <div style="font-size: 20px">
                      {{ taskGroup.date | formatVariableDueDate("dddd") }}
                    </div>
                    <div class="mt-n1">
                      {{ taskGroup.date | formatVariableDueDate("MMMM YYYY") }}
                    </div>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-card>
            <v-timeline align-top dense class="ml-n3">
              <v-timeline-item
                color="#00A9F4"
                v-for="task of taskGroup.groupedTasks"
                :key="task.item_id"
                fill-dot
              >
                <v-avatar
                  slot="icon"
                  @click="
                    selectedTask = task;
                    openItem(task);
                  "
                  style="cursor: pointer"
                  :id="task.item_type + 'Btn'"
                >
                  <v-icon color="white"> {{ getIcon(task) }}</v-icon>
                </v-avatar>
                <div class="d-flex justify-space-between ml-n2">
                  <div class="pr-2">
                    <div class="caption">
                      {{ task.assigned_to_name }}
                    </div>
                    <div>
                      <p
                        v-if="task.description"
                        class="caption font-weight-bold py-0 my-0"
                      >
                        {{ task.description }}
                      </p>
                      <p v-else class="caption font-weight-bold py-0 my-0">
                        No task description set
                      </p>
                    </div>
                    <div class="py-1" v-if="task.item_type === 'action_item'">
                      <v-chip color="#e0e0e0" :disabled="true">
                        {{ getPillText(task) }}
                      </v-chip>
                    </div>
                  </div>
                  <div v-if="task.item_type === 'task'">
                    <v-menu class="py-0 my-0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="py-0 my-0"
                          id="taskOptionsBtn"
                        >
                          <v-icon>{{ mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list min-width="150px">
                        <v-list-item
                          class="d-flex flex-no-wrap gap cursor-pointer"
                          @click="
                            selectedTask = task;
                            showEditTaskDialog = true;
                          "
                        >
                          <v-icon>
                            {{ mdiPencil }}
                          </v-icon>
                          <div>Edit Task</div>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
        <div v-else class="py-2" style="text-align: center; color: #444444">
          No tasks created
        </div>
      </section>

      <AddTaskDialog
        v-if="showAddTaskDialog"
        :selectedMapServiceId="selectedMapServiceId"
        :showAddTaskDialog="showAddTaskDialog"
        :globalId="globalId"
        :objectId="objectId"
        @add-task-success="
          showAddTaskDialog = false;
          refreshTasks();
        "
        @add-task-dialog-close="showAddTaskDialog = false"
      />

      <EditTaskDialog
        v-if="showEditTaskDialog"
        :selectedMapServiceId="selectedMapServiceId"
        :showEditTaskDialog="showEditTaskDialog"
        :selectedTask="selectedTask"
        :globalId="globalId"
        :objectId="objectId"
        @edit-task-success="
          showEditTaskDialog = false;
          refreshTasks();
        "
        @edit-task-dialog-close="showEditTaskDialog = false"
      />

      <ActionItemTabActionItemDialog
        v-if="showActionItemDialog"
        :action="getActionItemLabel(selectedTask)"
        :showActionItemDialog="showActionItemDialog"
        :selectedActionItem="fullActionItemData.data.results"
        :selectedActionItemId="selectedTask.item_id"
        :selectedMapServiceId="selectedMapServiceId"
        :objectId="objectId"
        @action-item-dialog-close="showActionItemDialog = false"
        @action-item-saved="refreshTasks()"
        @action-item-response-saved="
          showActionItemDialog = false;
          refreshTasks();
        "
        @reject-action-item="
          showActionItemDialog = false;
          refreshTasks();
        "
        @accept-action-item="
          showActionItemDialog = false;
          refreshTasks();
        "
      />

      <v-dialog
        v-model="showTicketEditForm"
        max-width="600"
        close
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <TicketEditForm
            v-if="showTicketEditForm"
            :selectedMapServiceId="selectedMapServiceId"
            :formDefinitionId="selectedTask.form_definition_id"
            :taskId="selectedTask.item_id"
            :globalId="globalId"
            :objectId="objectId"
            @ticket-edit-form-close-button-click="showTicketEditForm = false"
            @ticket-edit-form-close="
              showTicketEditForm = false;
              refreshTasks();
              $emit('ticket-edit-form-close');
            "
            @ticket-edit-form-submitted="
              showTicketEditForm = false;
              refreshTasks();
              $emit('ticket-edit-form-submitted');
            "
          />
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {
  mdiReload,
  mdiPlus,
  mdiDotsVertical,
  mdiPencil,
  mdiCheckboxMarkedOutline,
  mdiCalendarCheck,
  mdiRefresh,
} from "@mdi/js";
import AddTaskDialog from "@/components/mapView/tasks-tab/AddTaskDialog";
import EditTaskDialog from "@/components/mapView/tasks-tab/EditTaskDialog";
import TicketEditForm from "@/components/tickets/TicketEditForm.vue";
import ActionItemTabActionItemDialog from "@/components/mapView/action-items-tab/ActionItemTabActionItemDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import moment from "moment";
import actionItemMixin from "@/mixins/actionItemMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "TasksTab",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    inExpandInfoDialog: Boolean,
  },
  components: {
    AddTaskDialog,
    EditTaskDialog,
    TicketEditForm,
    ActionItemTabActionItemDialog,
  },
  mixins: [actionItemMixin],
  data() {
    return {
      showAddTaskDialog: false,
      showEditTaskDialog: false,
      showTicketEditForm: false,
      showActionItemDialog: false,
      mdiReload,
      mdiPlus,
      mdiDotsVertical,
      mdiPencil,
      mdiCheckboxMarkedOutline,
      mdiCalendarCheck,
      mdiRefresh,
      tasks: [],
      selectedTask: {},
      loadingTasks: false,
      ACTION_ITEM_STATUSES,
      fullActionItemData: {},
      tasksTabHeight: window.innerHeight - 250,
    };
  },
  computed: {
    taskGroups() {
      const filteredTasks = this.tasks.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled"
      );
      const filteredTasksDates = [
        ...new Set(
          filteredTasks.map((t) => {
            if (moment.utc(t?.due_date).isValid()) {
              return moment.utc(t?.due_date).format("YYYY-MM-DD");
            } else {
              return moment.utc().format("YYYY-MM-DD");
            }
          })
        ),
      ];
      const groups = filteredTasksDates.map((date) => {
        return {
          date,
          groupedTasks: filteredTasks.filter((t) => {
            if (moment.utc(t?.due_date).isValid()) {
              return (
                moment.utc(t?.due_date).format("YYYY-MM-DD") ===
                moment.utc(date).format("YYYY-MM-DD")
              );
            } else {
              return (
                moment.utc().format("YYYY-MM-DD") ===
                moment.utc(date).format("YYYY-MM-DD")
              );
            }
          }),
        };
      });
      return groups;
    },
  },
  methods: {
    onResize() {
      this.tasksTabHeight = window.innerHeight - 250;
    },
    getPillText(task) {
      if (task.status === "rejected") {
        return "Rejected";
      } else if (task.status === "ready_for_review") {
        return "Ready for Review";
      } else {
        return "Open";
      }
    },
    openItem(task) {
      if (task.item_type === "task") {
        this.showTicketEditForm = true;
      } else if (task.item_type === "action_item") {
        this.openActionItem(task.item_id);
      }
    },
    async openActionItem(actionItemId) {
      this.fullActionItemData = await axiosWithRegularAuth.get(
        `${APIURL}/action_item/${actionItemId}`
      );
      this.showActionItemDialog = true;
    },
    async getActiveTasks() {
      const { objectId, selectedMapServiceId } = this;
      if (!objectId || !selectedMapServiceId || !navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/action_item_and_tasks`, {
        params: {
          map_service_id: selectedMapServiceId,
          object_id: objectId,
        },
      });
      this.tasks = results;
      const filteredTasks = this.tasks.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled"
      );
      this.$emit("update-task-count", filteredTasks.length);
    },
    async refreshTasks() {
      await this.getActiveTasks();
      this.$emit("tasks-refreshed");
    },
    getIcon(taskItem) {
      if (taskItem.item_type === "action_item") {
        return "mdi-checkbox-marked-outline";
      } else if (taskItem.item_type === "task") {
        return "mdi-calendar-check";
      } else {
        return "";
      }
    },
  },
  async beforeMount() {
    window.addEventListener("resize", this.onResize);
    this.loadingTasks = true;
    await this.refreshTasks();
    this.loadingTasks = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    async objectId() {
      this.loadingTasks = true;
      await this.refreshTasks();
      this.loadingTasks = false;
    },
    async selectedMapServiceId() {
      this.loadingTasks = true;
      await this.refreshTasks();
      this.loadingTasks = false;
    },
    async loadingTasks(val) {
      if (!val) {
        await this.$nextTick();
        this.tasksTabHeight = window.innerHeight - 250;
      }
    },
  },
};
</script>

<style scoped>
.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 12px;
  height: 24px;
  font-weight: 500;
}

.v-chip--disabled {
  opacity: 1 !important;
}
.gap {
  gap: 15px;
}
</style>
