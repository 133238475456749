var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "template-editor" },
    [
      _vm.editor
        ? _c(
            "section",
            {
              staticClass:
                "\n      d-flex\n      align-center\n      flex-wrap\n      border\n      px-2\n      py-1\n      justify-space-between\n    ",
              staticStyle: { "background-color": "#f1f1f1" }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-wrap flex-grow-0" },
                [
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("bold") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatBold))])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("italic") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatItalic))])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("underline") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleUnderline()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatUnderline))])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("bulletList") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBulletList()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatListBulleted))])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: {
                        "is-active": _vm.editor.isActive("orderedList")
                      },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleOrderedList()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatListNumbered))])],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-width": 200,
                        "offset-x": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: {
                                          "is-active": _vm.editor.isActive(
                                            "link"
                                          )
                                        },
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.getLink()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiLink))])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1658899671
                      ),
                      model: {
                        value: _vm.showLinkMenu,
                        callback: function($$v) {
                          _vm.showLinkMenu = $$v
                        },
                        expression: "showLinkMenu"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pt-2 pb-3 px-4" },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Input URL" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        var valid = ref.valid
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "pt-1",
                                            attrs: {
                                              label: "Input URL",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "name"
                                            },
                                            model: {
                                              value: _vm.inputURL,
                                              callback: function($$v) {
                                                _vm.inputURL = $$v
                                              },
                                              expression: "inputURL"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2111130779
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showLinkMenu = false
                                    }
                                  }
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { dark: "", color: "#3f51b5" },
                                  on: {
                                    click: function($event) {
                                      _vm.showLinkMenu = false
                                      _vm.setLink(_vm.inputURL)
                                    }
                                  }
                                },
                                [_vm._v(" Set URL ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { dark: "", color: "#286054" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(_vm.mdiPlus))
                                ]),
                                _vm._v(" Smart Field "),
                                _c("v-icon", [
                                  _vm._v(_vm._s(_vm.mdiChevronDown))
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3684974166
                  )
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "pa-0 ma-0" },
                    _vm._l(_vm.smartFieldChoices, function(field) {
                      return _c(
                        "v-list-item",
                        {
                          key: field.value,
                          staticClass: "py-1 px-4 mx-0 justify-space-between",
                          on: {
                            click: function($event) {
                              return _vm.insertContent(field.value)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(field.label) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("editor-content", {
        staticClass: "border",
        attrs: { editor: _vm.editor }
      }),
      Array.isArray(_vm.errors)
        ? _c("p", { staticClass: "caption red--text" }, [
            _vm._v(" " + _vm._s(_vm.errors[0]) + " ")
          ])
        : _vm._e(),
      _vm.showUrlEditorDialog
        ? _c("UrlEditorDialog", {
            attrs: { showUrlEditorDialog: _vm.showUrlEditorDialog },
            on: { input: _vm.setLink },
            model: {
              value: _vm.previousUrl,
              callback: function($$v) {
                _vm.previousUrl = $$v
              },
              expression: "previousUrl"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }