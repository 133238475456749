<template>
  <v-dialog
    :value="showNotificationTemplateDialog"
    max-width="500px"
    persistent
  >
    <validation-observer
      slim
      ref="notificationTemplateForm"
      v-slot="{ valid: isFormValid }"
    >
      <form @submit.prevent="save">
        <v-card>
          <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
            <div>Notification Template</div>
            <v-spacer />
            <v-btn icon @click="$emit('notification-template-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text
            class="pt-3"
            id="dynamic-form-card-text"
            :style="{
              height: $vuetify.breakpoint.xsOnly
                ? `${contentHeight}px`
                : '65vh',
            }"
          >
            <v-card>
              <v-card-text class="px-3 py-5">
                <validation-provider
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                  name="Template Name"
                >
                  <v-text-field
                    v-model="
                      notificationTemplate.task_notification_template_name
                    "
                    label="Template Name *"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                  >
                  </v-text-field>
                </validation-provider>
              </v-card-text>
            </v-card>

            <section class="d-flex justify-space-between align-center pt-2">
              <div class="caption">Notifications</div>
              <v-btn text @click="addNotification" color="#3F51B5">
                <v-icon small>{{ mdiPlus }}</v-icon>
                Notification
              </v-btn>
            </section>

            <v-card
              v-for="(notification, notificationIndex) of notificationTemplate
                .task_notification_template.notifications"
              :key="notification.id"
              class="border-radius-0 my-2"
            >
              <v-card-text
                class="py-2 px-3 d-flex justify-space-between align-center"
                @click.stop="onToggleNotification(notification.id)"
                v-ripple
              >
                <validation-provider
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                  name="Notification Name"
                >
                  <v-text-field
                    v-model="notification.name"
                    label="Notification Name *"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    @click.stop
                  >
                  </v-text-field>
                </validation-provider>

                <section class="d-flex align-center">
                  <v-icon>{{
                    showNotificationContent[notification.id]
                      ? mdiChevronUp
                      : mdiChevronDown
                  }}</v-icon>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" class="px-0 mx-0">
                        <v-icon>{{ mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        class="d-flex flex-no-wrap gap px-3 mx-0 cursor-pointer"
                        @click="duplicateNotification(notificationIndex)"
                      >
                        <div>
                          <v-icon>
                            {{ mdiContentCopy }}
                          </v-icon>
                        </div>
                        <div>Duplicate</div>
                      </v-list-item>
                      <v-list-item
                        class="d-flex flex-no-wrap gap px-3 mx-0 cursor-pointer"
                        @click="deleteNotification(notificationIndex)"
                      >
                        <div>
                          <v-icon>
                            {{ mdiDelete }}
                          </v-icon>
                        </div>
                        <div>Delete</div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </section>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-text
                class="px-3 py-1"
                v-if="showNotificationContent[notification.id]"
              >
                <section class="d-flex gap pb-2">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="From Name"
                  >
                    <v-text-field
                      v-model="notification.from_name"
                      label="From Name"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    :rules="{ email: true }"
                    v-slot="{ errors, valid }"
                    name="Reply To"
                  >
                    <v-text-field
                      v-model="notification.reply_to"
                      label="Reply To"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-text-field>
                  </validation-provider>
                </section>

                <v-divider></v-divider>

                <section class="d-flex gap pb-2">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Subject"
                  >
                    <v-text-field
                      v-model="notification.subject"
                      label="Subject"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      :ref="`subjectInput-${notificationIndex}`"
                    >
                      <template v-slot:append>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="#286054"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>{{ mdiPlus }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list class="pa-0 ma-0">
                            <v-list-item
                              class="py-1 px-4 mx-0 justify-space-between"
                              v-for="field of smartFieldChoices.slice(0, 2)"
                              :key="field.value"
                              @click="
                                insertSubjectPlaceholder(
                                  field.value,
                                  notificationIndex
                                )
                              "
                            >
                              {{ field.label }}
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item
                              class="py-1 px-4 mx-0 justify-space-between"
                              v-for="field of smartFieldChoices.slice(2)"
                              :key="field.value"
                              @click="
                                insertSubjectPlaceholder(
                                  field.value,
                                  notificationIndex
                                )
                              "
                            >
                              {{ field.label }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </section>

                <v-divider></v-divider>

                <NotificationTemplateBodyEditor
                  class="py-2"
                  v-model="notification.body"
                  :assignTo="assignTo"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="objectId"
                />

                <section class="py-1 d-flex gap flex-wrap align-center">
                  <div class="font-weight-medium caption">
                    Send Notification
                  </div>

                  <validation-provider
                    :rules="{ required: true, min_value: 0 }"
                    v-slot="{ errors, valid }"
                    :name="`# of Days ${notificationIndex}`"
                  >
                    <v-text-field
                      v-model="notification.notification_date.value"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      class="py-0 my-0"
                      type="number"
                      placeholder="# of Days"
                      style="width: 50px"
                    >
                    </v-text-field>
                  </validation-provider>

                  <div class="font-weight-medium caption">Days(s)</div>

                  <validation-provider
                    :rules="{ required: true }"
                    v-slot="{ errors, valid }"
                    :name="`Before or After ${notificationIndex}`"
                  >
                    <v-select
                      :items="BEFORE_AFTER_CHOICES"
                      v-model="notification.notification_date.before_or_after"
                      item-text="label"
                      item-value="value"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      class="py-0 my-0"
                    >
                    </v-select>
                  </validation-provider>

                  <div class="caption font-weight-medium">Task Due Date</div>
                </section>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions class="d-flex justify-end pa-3">
            <v-btn
              :dark="isFormValid"
              color="#3F51B5"
              type="submit"
              :disabled="!isFormValid"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import {
  mdiDotsVertical,
  mdiClose,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
  mdiContentCopy,
  mdiDelete,
} from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import { axiosWithRegularAuth } from "@/plugins/axios";
import NotificationTemplateBodyEditor from "@/components/mapView/tasks-tab/shared/notification-template-dialog/NotificationTemplateBodyEditor";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import { cloneDeep } from "lodash";
import {
  SMART_FIELD_CHOICES,
  SMART_FIELD,
} from "@/constants/notificationTemplate";
import { ASSIGN_TO } from "@/constants/actionItemChoices";

const BEFORE_AFTER_CHOICES = [
  { label: "Before", value: "before" },
  { label: "After", value: "after" },
];
const APIURL = process.env.VUE_APP_API_URL;
const notification = {
  name: "",
  body: "",
  from_name: "",
  reply_to: "",
  notification_date: {
    before_or_after: "before",
    unit: "days",
    value: 0,
  },
};

export default {
  name: "NotificationTemplateDialog",
  mixins: [contentHeightMixin],
  data() {
    const notificationId = uuidv4();
    return {
      mdiDotsVertical,
      mdiClose,
      mdiPlus,
      mdiChevronUp,
      mdiChevronDown,
      mdiContentCopy,
      mdiDelete,
      notificationTemplate: {
        task_notification_template_name: "",
        task_notification_template: {
          notifications: [{ id: notificationId, ...notification }],
        },
      },
      showNotificationContent: {},
      BEFORE_AFTER_CHOICES,
      SMART_FIELD_CHOICES,
    };
  },
  props: {
    showNotificationTemplateDialog: Boolean,
    assignTo: String,
    selectedMapServiceId: String,
    objectId: Number,
  },
  components: {
    NotificationTemplateBodyEditor,
  },
  computed: {
    smartFieldChoices() {
      if (this.assignTo === ASSIGN_TO.SITE_CONTACT) {
        return SMART_FIELD_CHOICES.filter(
          ({ value }) => value !== SMART_FIELD.USER_SITE_LINK
        );
      } else if (this.assignTo === ASSIGN_TO.ORG_USER) {
        return SMART_FIELD_CHOICES.filter(
          ({ value }) => value !== SMART_FIELD.SITE_CONTACT_SITE_LINK
        );
      }
      return SMART_FIELD_CHOICES;
    },
  },
  beforeMount() {
    this.showNotificationPanes();
  },
  methods: {
    showNotificationPanes(notificationId) {
      const entries =
        this.notificationTemplate.task_notification_template.notifications.map(
          ({ id }) => {
            if (id === notificationId) {
              return [id, true];
            }
            return [id, false];
          }
        );
      this.showNotificationContent = Object.fromEntries(entries);
    },
    addNotification() {
      const notificationId = uuidv4();
      const newNotification = {
        ...cloneDeep(notification),
        id: notificationId,
      };
      this.notificationTemplate.task_notification_template.notifications.push(
        newNotification
      );
      this.showNotificationPanes(notificationId);
    },
    duplicateNotification(notificationIndex) {
      const existingNotification =
        this.notificationTemplate.task_notification_template.notifications[
          notificationIndex
        ];
      const newNotification = { ...existingNotification, id: uuidv4() };
      this.notificationTemplate.task_notification_template.notifications = [
        ...this.notificationTemplate.task_notification_template.notifications.slice(
          0,
          notificationIndex
        ),
        newNotification,
        ...this.notificationTemplate.task_notification_template.notifications.slice(
          notificationIndex
        ),
      ];
    },
    deleteNotification(notificationIndex) {
      this.notificationTemplate.task_notification_template.notifications.splice(
        notificationIndex,
        1
      );
    },
    onToggleNotification(notificationId) {
      this.$set(
        this.showNotificationContent,
        notificationId,
        !this.showNotificationContent[notificationId]
      );
    },
    async save() {
      const success = await this.$refs.notificationTemplateForm.validate();
      if (!success) {
        return;
      }
      const payload = cloneDeep(this.notificationTemplate);
      const transformedPayload = {
        ...payload,
        task_notification_template: {
          notifications: payload.task_notification_template.notifications.map(
            ({ id, ...rest }) => rest
          ),
        },
      };
      await axiosWithRegularAuth.post(
        `${APIURL}/task_notification_templates`,
        transformedPayload
      );
      this.$emit("notification-template-saved");
    },
    insertSubjectPlaceholder(value, notificationIndex) {
      let placeholder;
      const [inputEl] = this.$refs[`subjectInput-${notificationIndex}`];
      const index = inputEl.$refs.input.selectionStart;

      if (value === SMART_FIELD.DUE) {
        placeholder = "{{task.due}}";
      } else if (value === SMART_FIELD.USER_SITE_LINK) {
        placeholder = "{{userSiteLink}}";
      } else if (value === SMART_FIELD.SITE_CONTACT_SITE_LINK) {
        placeholder = "{{siteContactSiteLink}}";
      } else if (value === SMART_FIELD.SITE_NUMBER) {
        placeholder = "{{ufield.site_number}}";
      } else if (value === SMART_FIELD.STATUS_WRITTEN) {
        placeholder = "{{ufield.Status_written}}";
      }

      if (placeholder) {
        const { subject = "" } =
          this.notificationTemplate.task_notification_template.notifications[
            notificationIndex
          ];
        const start = subject.slice(0, index);
        const end = subject.slice(index);
        this.$set(
          this.notificationTemplate.task_notification_template.notifications[
            notificationIndex
          ],
          "subject",
          `${start}${placeholder}${end}`
        );
      }
    },
  },
};
</script>

<style>
#dynamic-form-card-text {
  height: 60vh;
  overflow-y: auto;
}
</style>
