var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showEditTaskDialog, "max-width": "500px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Edit Scheduled Form")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("edit-task-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveTask.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "overflow-y-auto",
                      staticStyle: {
                        "background-color": "#fafafa",
                        "max-height": "60vh"
                      }
                    },
                    [
                      _c("p", { staticClass: "caption" }, [
                        _vm._v("Scheduled Form Info")
                      ]),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Form To Be Completed",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.formChoices,
                                                      "hide-details": "auto",
                                                      name: "form",
                                                      "item-value": "value",
                                                      "item-text": "name",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      label:
                                                        "Form To Be Completed *"
                                                    },
                                                    model: {
                                                      value: _vm.task.formDefId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "formDefId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "task.formDefId"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm.selectedMapService.site_enabled
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex gap justify-space-between",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "half" },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Assign To",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        var valid = ref.valid
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              items:
                                                                _vm.ASSIGN_TO_CHOICES,
                                                              "hide-details":
                                                                "auto",
                                                              name: "assignTo",
                                                              "item-value":
                                                                "value",
                                                              "item-text":
                                                                "label",
                                                              "error-messages": errors,
                                                              success: valid,
                                                              label:
                                                                "Assign To *"
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                _vm.taskAssignedTo = {}
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.assignTo,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.assignTo = $$v
                                                              },
                                                              expression:
                                                                "assignTo"
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  304906797
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.assignTo === _vm.ASSIGN_TO.SITE_CONTACT
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex gap justify-space-between",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "half" },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Site Contact",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        var valid = ref.valid
                                                        return [
                                                          _c("v-combobox", {
                                                            attrs: {
                                                              items:
                                                                _vm.sharedSiteContactsChoices,
                                                              "hide-details":
                                                                "auto",
                                                              name:
                                                                "siteContact",
                                                              "item-value":
                                                                "value",
                                                              "error-messages": errors,
                                                              success: valid,
                                                              label:
                                                                "Site Contact *",
                                                              "return-object":
                                                                ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var item =
                                                                      ref.item
                                                                    return [
                                                                      _c(
                                                                        "section",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "caption"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.email
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key:
                                                                    "selection",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var item =
                                                                      ref.item
                                                                    return [
                                                                      _c(
                                                                        "section",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.taskAssignedTo,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.taskAssignedTo = $$v
                                                              },
                                                              expression:
                                                                "taskAssignedTo"
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  525744395
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm.assignTo === _vm.ASSIGN_TO.ORG_USER
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex gap justify-space-between",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "half" },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Assigned To",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      var valid = ref.valid
                                                      return [
                                                        _c("v-autocomplete", {
                                                          staticClass:
                                                            "autocomplete",
                                                          attrs: {
                                                            items:
                                                              _vm.userChoices,
                                                            "hide-details":
                                                              "auto",
                                                            name: "assignedTo",
                                                            "item-value":
                                                              "value",
                                                            "item-text": "name",
                                                            "error-messages": errors,
                                                            success: valid,
                                                            label:
                                                              "Assigned To *",
                                                            "return-object": ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.taskAssignedTo,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.taskAssignedTo = $$v
                                                            },
                                                            expression:
                                                              "taskAssignedTo"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Due Date",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        "error-messages": errors,
                                                                        success: valid,
                                                                        color:
                                                                          "#3F51B5",
                                                                        label:
                                                                          "Due Date *"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "append",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.mdiCalendar
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .due,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.task,
                                                                            "due",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "task.due"
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("v-date-picker", {
                                                        model: {
                                                          value: _vm.task.due,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.task,
                                                              "due",
                                                              $$v
                                                            )
                                                          },
                                                          expression: "task.due"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Status",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.statusChoices,
                                                      "hide-details": "auto",
                                                      name: "status",
                                                      "item-value": "value",
                                                      "item-text": "name",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      label: "Status *"
                                                    },
                                                    model: {
                                                      value: _vm.task.status,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "task.status"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "Description" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              var valid = ref.valid
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    success: valid,
                                                    label: "Description",
                                                    color: "#3F51B5"
                                                  },
                                                  model: {
                                                    value: _vm.task.description,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.task,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "task.description"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-end" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "half" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Notification Template"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    var valid = ref.valid
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.taskNotificationTemplateChoices,
                                                          "hide-details":
                                                            "auto",
                                                          name:
                                                            "notificationTemplate",
                                                          "item-value": "value",
                                                          "item-text": "name",
                                                          "error-messages": errors,
                                                          success: valid,
                                                          label:
                                                            "Notification Template",
                                                          clearable: "",
                                                          disabled: !_vm.isOnline
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "prepend-item",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      staticClass:
                                                                        "py-0",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.showNotificationTemplateDialog = true
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "addTemplateButton",
                                                                          staticStyle: {
                                                                            color:
                                                                              "#3f51b5"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "#3f51b5",
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.mdiPlus
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " Notification Template "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.task
                                                              .taskNotificationTemplateId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.task,
                                                              "taskNotificationTemplateId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "task.taskNotificationTemplateId"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.task.taskNotificationTemplateId &&
                                        _vm.isGisAdmin
                                          ? _c(
                                              "v-menu",
                                              {
                                                attrs: { "offset-y": "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mdiDotsVertical
                                                                  )
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1924150619
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  { staticClass: "px-0 mx-0" },
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        staticClass:
                                                          "\n                            d-flex\n                            flex-no-wrap\n                            gap\n                            px-3\n                            mx-0\n                            cursor-pointer\n                          ",
                                                        on: {
                                                          click:
                                                            _vm.editSelectedNotificationTemplate
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.mdiPencil
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", [
                                                          _vm._v("Edit")
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        staticClass:
                                                          "\n                            d-flex\n                            flex-no-wrap\n                            gap\n                            px-3\n                            mx-0\n                            cursor-pointer\n                          ",
                                                        on: {
                                                          click:
                                                            _vm.deleteSelectedNotificationTemplate
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.mdiDelete
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", [
                                                          _vm._v("Delete")
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showNotificationTemplateDialog
                        ? _c("NotificationTemplateDialog", {
                            attrs: {
                              showNotificationTemplateDialog:
                                _vm.showNotificationTemplateDialog,
                              assignTo: _vm.assignTo,
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.objectId
                            },
                            on: {
                              "notification-template-dialog-close": function(
                                $event
                              ) {
                                _vm.showNotificationTemplateDialog = false
                              },
                              "notification-template-saved": function($event) {
                                _vm.showNotificationTemplateDialog = false
                                _vm.getTaskNotificationTemplates()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.showEditNotificationTemplateDialog
                        ? _c("EditNotificationTemplateDialog", {
                            attrs: {
                              selectedNotificationTemplate:
                                _vm.selectedNotificationTemplate,
                              assignTo: _vm.assignTo,
                              showEditNotificationTemplateDialog:
                                _vm.showEditNotificationTemplateDialog,
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.objectId
                            },
                            on: {
                              "notification-template-dialog-close": function(
                                $event
                              ) {
                                _vm.showEditNotificationTemplateDialog = false
                              },
                              "notification-template-saved": function($event) {
                                _vm.showEditNotificationTemplateDialog = false
                                _vm.getTaskNotificationTemplates()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end py-3 px-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { type: "submit", color: "#3F51B5", dark: "" }
                        },
                        [_vm._v("Update")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }