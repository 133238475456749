<template>
  <validation-observer ref="fieldValueForm">
    <form @submit.prevent="save">
      <validation-provider v-slot="{ errors, valid }" name="Value">
        <template v-if="fieldType === 'esriFieldTypeString'">
          <div class="select" v-if="Array.isArray(codeValues)">
            <v-select
              style="z-index: 100"
              :items="codeValues"
              v-model="inputValue"
              item-text="name"
              item-value="code"
              hide-details
              class="py-0 my-0"
            >
            </v-select>
          </div>
          <template v-else>
            <QuillEditor
              v-if="richTextField"
              v-model="inputValue"
              height="120px"
              :showExtraButtons="false"
            />
            <v-text-field
              v-else
              :error-messages="errors"
              :success="valid"
              v-model="inputValue"
              hide-details
              class="py-0 my-0"
            >
            </v-text-field>
          </template>
        </template>
        <template
          v-else-if="
            [
              'esriFieldTypeSmallInteger',
              'esriFieldTypeDouble',
              'esriFieldTypeInteger',
            ].includes(fieldType)
          "
        >
          <v-text-field
            :error-messages="errors"
            :success="valid"
            v-model.number="inputValue"
            hide-details
            type="number"
            class="py-0 my-0"
          >
          </v-text-field>
        </template>
        <v-menu
          v-else-if="fieldType === 'esriFieldTypeDate'"
          style="z-index: 100"
        >
          <template v-slot:activator="{ on }">
            <validation-provider :rules="{ required: true }">
              <v-text-field v-on="on" :value="selectedDate" hide-details>
                <template #append>
                  <v-icon color="#3F51B5">
                    {{ mdiCalendar }}
                  </v-icon>
                </template>
              </v-text-field>
            </validation-provider>
          </template>
          <v-date-picker v-model="inputValue" style="z-index: 100">
          </v-date-picker>
        </v-menu>
      </validation-provider>

      <section class="d-flex justify-end py-2">
        <v-btn text @click.stop="$emit('cancel-edit')">Cancel</v-btn>
        <v-btn dark color="#3F51B5" type="submit">Save</v-btn>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { mdiCalendar } from "@mdi/js";
import moment from "moment";
import QuillEditor from "@/components/shared/QuillEditor";

export default {
  name: "FeatureItemGisFieldInput",
  props: {
    value: [String, Number],
    fieldType: String,
    richTextField: Boolean,
    featureItemFields: Array,
    gisInfoAttributeKey: String,
  },
  components: { QuillEditor },
  data() {
    return {
      inputValue: "",
      mdiCalendar,
      isEditing: false,
    };
  },
  computed: {
    selectedDate() {
      const date = moment(this.inputValue, ["MM/DD/YYYY", "YYYY-MM-DD"], true);
      if (date.isValid()) {
        return date.format("MM/DD/YYYY");
      }
      return "";
    },
    codeValues() {
      return this.featureItemFields?.find(
        (f) =>
          f.name === this.gisInfoAttributeKey ||
          f.alias === this.gisInfoAttributeKey
      )?.domain?.codedValues;
    },
  },
  methods: {
    async save() {
      const success = await this.$refs.fieldValueForm.validate();
      if (!success) {
        return;
      }

      if (this.fieldType === "esriFieldTypeDate") {
        this.$emit("input", moment(this.inputValue).format("MM/DD/YYYY"));
      } else {
        this.$emit("input", this.inputValue);
      }
    },
  },
  mounted() {
    if (this.fieldType === "esriFieldTypeDate") {
      if (moment(this.value, "MM/DD/YYYY", true).isValid()) {
        this.inputValue = moment(this.value, "MM/DD/YYYY").format("YYYY-MM-DD");
      }
    } else {
      this.inputValue = this.value;
    }
  },
};
</script>
