var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.showChooseTaskDialog, width: "330", persistent: "" }
    },
    [
      _c(
        "v-toolbar",
        {
          ref: "toolbar",
          staticClass: "elevation-0",
          attrs: { dark: "", color: "#3F51B5", width: "100%", id: "top-bar" }
        },
        [
          _c(
            "v-toolbar-title",
            {
              staticClass: "d-flex justify-space-between align-center",
              staticStyle: { width: "100%" }
            },
            [
              _c("div", [_vm._v("Select Open Task")]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("choose-task-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            {
              staticClass: "pa-5",
              style: {
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? _vm.contentHeight + "px"
                  : "65vh"
              },
              attrs: { id: "dynamic-form-card-text" }
            },
            [
              _c("p", [
                _vm._v(
                  " There is an open task to complete this form. Select the task to apply this form to. "
                )
              ]),
              _vm.taskGroups.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.taskGroups, function(taskGroup) {
                      return _c(
                        "div",
                        { key: taskGroup.date },
                        [
                          taskGroup.date !== "null"
                            ? _c(
                                "div",
                                { staticClass: "px-4 d-flex align-center gap" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-medium display-1 black--text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatVariableDueDate")(
                                              taskGroup.date,
                                              "DD"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("section", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "black--text",
                                        staticStyle: { "font-size": "20px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatVariableDueDate")(
                                                taskGroup.date,
                                                "dddd"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "black--text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatVariableDueDate")(
                                              taskGroup.date,
                                              "MMMM YYYY"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-timeline",
                            {
                              staticClass: "ml-n3",
                              attrs: { "align-top": "", dense: "" }
                            },
                            _vm._l(taskGroup.groupedTasks, function(task) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: task.item_id,
                                  attrs: { color: "#00A9F4", "fill-dot": "" }
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        slot: "icon",
                                        id: task.item_type + "Btn"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedTask = task
                                          _vm.openItem()
                                        }
                                      },
                                      slot: "icon"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.getIcon(task))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between ml-n2"
                                    },
                                    [
                                      _c("div", { staticClass: "pr-2" }, [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(task.assigned_to_name) +
                                              " "
                                          )
                                        ]),
                                        _c("div", [
                                          task.description
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "caption font-weight-bold py-0 my-0"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(task.description) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "caption font-weight-bold py-0 my-0"
                                                },
                                                [
                                                  _vm._v(
                                                    " No task description set "
                                                  )
                                                ]
                                              )
                                        ]),
                                        task.item_type === "action_item"
                                          ? _c(
                                              "div",
                                              { staticClass: "py-1" },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      color: "#e0e0e0",
                                                      disabled: true
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getPillText(task)
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "h2",
                { staticClass: "font-weight-regular black--text px-6 py-2" },
                [_vm._v("OR")]
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center gap",
                  staticStyle: { padding: "0 17px" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#3F51B5",
                        fab: "",
                        small: "",
                        dark: "",
                        id: "startFormBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("start-new-form")
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiNotePlusOutline) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular body-1 black--text" },
                    [_vm._v(" Start New Form ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }