<template>
  <div :class="{ background: inRightPane }">
    <section
      :style="contentHeight"
      :class="inExpandInfoDialog ? 'overflow-y-auto' : undefined"
    >
      <v-expansion-panels flat multiple v-model="openPanels">
        <v-expansion-panel value="0" :class="{ background: inRightPane }">
          <v-expansion-panel-header
            class="text-uppercase px-5"
            :class="{ background: inRightPane }"
          >
            UtiliSync Fields
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="py-0"
            :class="{ background: inRightPane }"
          >
            <div class="d-flex justify-end align-center px-2 py-0 my-0">
              <v-btn text color="#3F51B5" @click="reload">
                <v-icon>{{ mdiRefresh }}</v-icon>
                <span class="ml-1">Reload</span>
              </v-btn>
            </div>

            <div
              class="d-flex justify-end align-center gap caption px-6"
              :class="{ background: inRightPane }"
              v-if="gettingLatestUtiliSyncFields"
            >
              <v-progress-circular
                indeterminate
                :size="15"
              ></v-progress-circular>
              Checking for Updates
            </div>
            <template>
              <section class="px-9">
                <div class="d-flex align-center caption">Feature ID</div>
                <div>{{ objectId }}</div>
              </section>

              <section class="px-9">
                <div class="d-flex align-center caption">Global ID</div>
                <div>{{ globalId }}</div>
              </section>

              <section class="px-5 ma-0" :class="{ background: inRightPane }">
                <div
                  v-for="r of utiliSyncFields"
                  :key="r.gis_data_value_id"
                  class="py-1 ma-0"
                >
                  <div :class="{ background: inRightPane }">
                    <div
                      class="
                        caption
                        d-flex
                        align-center
                        justify-space-between
                        gap
                      "
                    >
                      <section class="d-flex align-center">
                        <v-icon
                          class="cursor-pointer pr-1"
                          v-if="isUtiliSyncFieldsEditable(r)"
                          small
                          @click="
                            $set(editingUtiliSyncField, r.name, true);
                            isEditing = true;
                          "
                          color="#3F51B5"
                        >
                          {{ mdiPencil }}
                        </v-icon>
                        <v-icon
                          class="cursor-pointer pr-1"
                          v-else
                          small
                          disabled
                        >
                          {{ mdiPencil }}
                        </v-icon>
                        <div>
                          {{ r.name }}
                        </div>
                      </section>

                      <v-switch
                        v-if="
                          editingUtiliSyncField[r.name] &&
                          getUtiliSyncFieldType(r) === 'string' &&
                          !isUtiliSyncFieldRefField(r)
                        "
                        v-model="richTextUtiliSyncField[r.name]"
                        label="Rich Text"
                      ></v-switch>
                    </div>
                    <div v-if="!editingUtiliSyncField[r.name]" class="pl-4">
                      <div
                        v-if="!isRichTextField(r.value)"
                        style="word-break: break-word"
                      >
                        {{ getUtiliSyncFieldValueLabel(r, r.value) }}
                      </div>
                      <div
                        v-else
                        class="ql-editor"
                        style="word-break: break-word"
                        v-html="r.value"
                      ></div>
                    </div>
                    <GisFieldInput
                      v-else
                      :richTextField="richTextUtiliSyncField[r.name]"
                      :fieldType="getUtiliSyncFieldType(r)"
                      :gisDataFieldOptions="r.gisDataFieldOptions"
                      @cancel-edit="
                        $set(editingUtiliSyncField, r.name, false);
                        isEditing = false;
                      "
                      v-model="r.value"
                      @input="
                        $set(editingUtiliSyncField, r.name, false);
                        isEditing = false;
                        saveUtiliSyncField(r.gisDataValueId, r.name, $event, r);
                      "
                    />
                  </div>
                </div>
              </section>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          value="1"
          :class="{ background: inRightPane }"
          v-if="notUtiliSyncLayer"
        >
          <v-expansion-panel-header
            class="text-uppercase nav-border-top my-0 py-0 px-5"
            :class="{ background: inRightPane }"
          >
            GIS Fields
          </v-expansion-panel-header>
          <v-expansion-panel-content
            v-if="showGisFields"
            class="my-0 py-0"
            :class="{ background: inRightPane }"
          >
            <div class="d-flex justify-end align-center px-2 py-0 my-0">
              <v-btn text color="#3F51B5" @click="loadLatestGisData">
                <v-icon>{{ mdiRefresh }}</v-icon>
                <span class="ml-1">Reload</span>
              </v-btn>
            </div>

            <template v-if="isOnline">
              <div
                v-if="loadingGisFields"
                class="d-flex justify-center align-center"
                :class="{ background: inRightPane }"
              >
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
              <template v-else>
                <section
                  class="px-5 ma-0"
                  v-if="Object.keys(gisInfoAttributesToDisplay).length > 0"
                >
                  <div
                    class="py-1 ma-0"
                    v-for="(value, key) in gisInfoAttributesToDisplay"
                    :key="key"
                  >
                    <div
                      class="caption d-flex align-center justify-space-between"
                    >
                      <div class="d-flex">
                        <v-icon
                          v-if="isGisFieldsEditable(key)"
                          class="cursor-pointer pr-1"
                          small
                          @click="
                            $set(editingGisField, key, true);
                            isEditing = true;
                          "
                          color="#3F51B5"
                        >
                          {{ mdiPencil }}
                        </v-icon>

                        <div v-if="isGisFieldsEditable(key)">
                          {{ key }}
                        </div>
                        <div v-else class="ml-4">
                          {{ key }}
                        </div>
                      </div>

                      <v-switch
                        v-if="
                          editingGisField[key] &&
                          getEsriFieldType(key) === 'esriFieldTypeString' &&
                          !Array.isArray(getCodeValues(key)) &&
                          !isEsriFieldRefField(key)
                        "
                        v-model="richTextField[key]"
                        label="Rich Text"
                      ></v-switch>
                    </div>

                    <div v-if="!editingGisField[key]" class="pl-4">
                      <div
                        v-if="!richTextField[key]"
                        style="word-break: break-word"
                      >
                        <div
                          v-if="
                            getEsriFieldType(key) === 'esriFieldTypeDate' &&
                            value !== null
                          "
                        >
                          {{ new Date(value) | formatDate }}
                        </div>
                        <div v-else>
                          <template v-if="!Array.isArray(getCodeValues(key))">
                            {{ value }}
                          </template>
                          <template v-else>
                            {{ getNameForCodeValue(key, value) }}
                          </template>
                        </div>
                      </div>

                      <div
                        v-else
                        v-html="value"
                        style="word-break: break-word"
                      ></div>
                    </div>
                    <FeatureItemGisFieldInput
                      v-else
                      :richTextField="richTextField[key]"
                      :fieldType="getEsriFieldType(key)"
                      :featureItemFields="featureItemFields"
                      :gisInfoAttributeKey="key"
                      @cancel-edit="
                        $set(editingGisField, key, false);
                        isEditing = false;
                      "
                      v-model="gisInfoAttributes[key]"
                      @input="
                        isEditing = false;
                        saveEsriField(key, $event);
                      "
                    />
                  </div>
                </section>
                <section class="px-5 py-3 ma-0" v-else>
                  <div class="caption">No GIS fields configured</div>
                </section>
              </template>
            </template>
            <section class="px-5 py-3 ma-0" v-else>
              <div class="caption">
                <v-icon class="mr-1" color="gray">
                  {{ mdiCloudOffOutline }}
                </v-icon>

                Offline. GIS attributes are unavailable.
              </div>
            </section>
          </v-expansion-panel-content>
          <v-expansion-panel-content
            v-else
            :style="{ 'background-color': '#fafafa' }"
          >
            <v-list class="pa-0 ma-0">
              <v-list-item class="px-5 ma-0">
                <v-list-item-content class="caption">
                  GIS Fields Not Available
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <ApplyEditResultDialog
        :showApplyEditResultDialog="showApplyEditResultDialog"
        :errorMessage="updateResultErrorMessage"
        @close="showApplyEditResultDialog = false"
      />
    </section>
  </div>
</template>

<script>
import { db } from "@/mixins/utilisync-db";
import { mdiPencil, mdiCloudOffOutline, mdiRefresh } from "@mdi/js";
import GisFieldInput from "@/components/shared/gis-info/GisFieldInput";
import FeatureItemGisFieldInput from "@/components/shared/gis-info/FeatureItemGisFieldInput";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import ApplyEditResultDialog from "@/components/app/ApplyEditResultDialog";
import sleep from "@/mixins/sleep";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "GisInfo",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    gisInfoAttributes: {
      type: Object,
      default() {
        return {};
      },
    },
    inRightPane: Boolean,
    featureItemFields: Array,
    inExpandInfoDialog: Boolean,
  },
  components: {
    GisFieldInput,
    FeatureItemGisFieldInput,
    ApplyEditResultDialog,
  },
  mixins: [networkStatusMixin, downloadDataMixin],
  data() {
    return {
      openPanels: [0, 1],
      loadingGisInfo: true,
      gisDataFields: [],
      gisDataValues: [],
      mapServices: [],
      mdiPencil,
      mdiCloudOffOutline,
      mdiRefresh,
      editingUtiliSyncField: {},
      editingGisField: {},
      isEditing: false,
      richTextField: {},
      richTextUtiliSyncField: {},
      gisDataFieldOptions: [],
      siteInfoHeight: window.innerHeight - 300,
      loadingGisFields: true,
      gettingLatestUtiliSyncFields: navigator.onLine,
      updateResultErrorMessage: "",
      showApplyEditResultDialog: false,
      latestFeatureItemAttributes: {},
      latestFields: [],
      latestGisDataLoaded: false,
    };
  },
  computed: {
    gisInfoAttributesToDisplay() {
      if (this.latestGisDataLoaded) {
        return this.latestGisInfoAttributes;
      }
      return this.gisInfoAttributes;
    },
    latestGisInfoAttributes() {
      const { latestFields, latestFeatureItemAttributes } = this;
      const entries = latestFields.map(({ name: key }) => {
        const value = latestFeatureItemAttributes[key];

        return [this.findAliasByName(latestFields, key), value];
      });
      return Object.fromEntries(entries);
    },
    contentHeight() {
      if (this.inExpandInfoDialog) {
        return {
          height: this.$vuetify.breakpoint.mdAndUp
            ? `${this.siteInfoHeight}px`
            : "auto",
        };
      }
      return {};
    },
    computeDate(secondsFromEpoch) {
      return new Date(secondsFromEpoch * 1000);
    },
    selectedMapService() {
      return this.mapServices?.find(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
    },
    isViewOnlyUser() {
      try {
        const auth = JSON.parse(localStorage.getItem("auth"));
        return auth?.is_view_only_user;
      } catch (error) {
        return true;
      }
    },
    notUtiliSyncLayer() {
      if (
        this.loadingGisFields ||
        this.gettingLatestUtiliSyncFields ||
        !this.selectedMapService
      ) {
        return false;
      }
      return this.selectedMapService?.service_type !== "U";
    },
    showGisFields() {
      const selectedMapService = this.mapServices.find?.(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      if (!selectedMapService) {
        return true;
      }
      return (
        (Boolean(localStorage.getItem("esri_token")) &&
          selectedMapService?.token_type === "AGOL") ||
        selectedMapService?.token_type === "NONE"
      );
    },
    utiliSyncFields() {
      if (!Array.isArray(this.gisDataFields)) {
        return [];
      }

      return this.gisDataFields?.map((gdf) => {
        const { name, gis_data_field_id: gisDataFieldId } = gdf;
        const gisDataValue = this.gisDataValues?.find((gdv) => {
          return (
            gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
            +gdv?.feature_id === +this.objectId
          );
        });
        const value = gisDataValue?.value;
        const gisDataValueId = gisDataValue?.gis_data_value_id;
        const gisDataFieldOptions = this.gisDataFieldOptions.filter(
          (g) => g.gis_data_field_id === gisDataFieldId
        );
        return {
          gisDataFieldId,
          gisDataValueId,
          name,
          value,
          gisDataFieldOptions,
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setIsRedrawLayers"]),
    async reload() {
      this.isEditing = false;
      this.editingGisField = {};
      this.editingUtiliSyncField = {};
      const { selectedMapServiceId } = this;
      await sleep(2000);
      this.setIsRedrawLayers({ selectedMapServiceId });
    },
    async getLatestUtiliSyncFields() {
      if (!navigator.onLine) {
        return;
      }
      this.gettingLatestUtiliSyncFields = true;
      await this.getDownloadSummary(true);
      await this.downloadGisDataFields();
      await this.downloadGisDataValues();
      await this.downloadUserDataValues(true);
      this.gettingLatestUtiliSyncFields = false;
      localStorage.setItem("last-updated", new Date().toString());
    },
    getUtiliSyncFieldValueLabel(utiliSyncField, value) {
      return (
        utiliSyncField?.gisDataFieldOptions?.find(
          (o) => o.value?.toString() === value?.toString()
        )?.label ?? value
      );
    },
    onResize() {
      this.siteInfoHeight = window.innerHeight - 300;
    },
    isUtiliSyncFieldRefField(utiliSyncField) {
      return (
        this.selectedMapService?.ref_field
          ?.toLowerCase()
          ?.replace(/[_ ]/g, "") ===
        utiliSyncField?.name?.toLowerCase()?.replace(/[_ ]/g, "")
      );
    },
    isEsriFieldRefField(esriFieldName) {
      return (
        this.selectedMapService?.ref_field
          ?.toLowerCase()
          ?.replace(/_ /g, "") ===
        esriFieldName?.toLowerCase()?.replace(/[_ ]/g, "")
      );
    },
    getCodeValues(gisInfoAttributeKey) {
      return this.featureItemFields?.find(
        (f) => f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
      )?.domain?.codedValues;
    },
    getNameForCodeValue(gisInfoAttributeKey, value) {
      return this.featureItemFields
        ?.find(
          (f) =>
            f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
        )
        ?.domain?.codedValues?.find?.((c) => c.code === value)?.name;
    },
    isRichTextField(inputValue) {
      return Boolean(inputValue?.match?.(/<[^>]*>/g));
    },
    getEsriFieldType(esriFieldKey) {
      const field = this.featureItemFields?.find(
        (f) => f.name === esriFieldKey || f.alias === esriFieldKey
      );
      return field?.type;
    },
    async saveEsriField(key, value) {
      const { objectId } = this;
      const fieldName = this.featureItemFields.find(
        (f) => f.name === key || f.alias === key
      )?.name;
      const objectIdFieldName = this.featureItemFields.find(
        (f) => f.type === "esriFieldTypeOID"
      )?.name;
      const formData = new FormData();
      formData.append("f", "json");
      formData.append("token", localStorage.getItem("esri_token"));
      formData.append(
        "updates",
        JSON.stringify([
          {
            attributes: {
              [objectIdFieldName ?? "OBJECTID"]: objectId,
              [fieldName]: value,
            },
          },
        ])
      );
      const selectedMapService = this.mapServices.find?.(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      const mapServiceUrl = selectedMapService?.service_url;
      const { data } = await axiosWithNoAuth.post(
        `${mapServiceUrl}/applyEdits`,
        formData
      );
      const [updateResult] = data?.updateResults ?? [];
      if (updateResult?.error) {
        this.updateResultErrorMessage = updateResult?.error?.description;
        this.showApplyEditResultDialog = true;
      } else {
        this.$set(this.editingGisField, key, false);
        this.$emit("esri-field-saved", this.selectedMapServiceId);
      }
    },
    async saveUtiliSyncField(gisDataValueId, key, value, utiliSyncField) {
      const { objectId } = this;
      const [gisDataValue] = await db.gisDataValues
        .filter((g) => {
          return (
            g.gis_data_value_id === gisDataValueId && g.feature_id === objectId
          );
        })
        .toArray();
      if (!gisDataValue) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/gis_data_values/by_gis_data_field_id_and_feature_id`,
          {
            params: {
              gis_data_field_id: utiliSyncField?.gisDataFieldId,
              feature_id: objectId,
            },
          }
        );

        if (!results) {
          const {
            data: { results: newGisDataValue },
          } = await axiosWithRegularAuth.post(
            `${APIURL}/gis_data_values/insert`,
            {
              gis_data_field_id: utiliSyncField?.gisDataFieldId,
              feature_id: objectId,
              value,
            }
          );
          await db.gisDataValues.put(newGisDataValue);
        } else {
          await db.gisDataValues.put(results);
        }
      } else {
        await db.gisDataValues.update(gisDataValue.gis_data_value_id, {
          value,
        });
        await axiosWithRegularAuth.put(`${APIURL}/gis_data_values`, {
          gis_data_value_id: gisDataValue?.gis_data_value_id,
          value,
        });
      }

      this.$emit("utilisync-field-saved", this.selectedMapServiceId);
    },
    getUtiliSyncFieldType(utiliSyncField) {
      const field = this.gisDataFields.find(
        (g) => g.gis_data_field_id === utiliSyncField.gisDataFieldId
      );
      return field?.type;
    },
    isUtiliSyncFieldsEditable(field) {
      if (this.isEditing || this.isViewOnlyUser) {
        return false;
      }
      return !["objectid", "globalid"].includes(field?.name?.toLowerCase());
    },
    isGisFieldsEditable(key) {
      if (this.isEditing || this.isViewOnlyUser) {
        return false;
      }
      return !["objectid", "globalid"].includes(key?.toLowerCase());
    },
    async getUtiliSyncFieldsAndValues() {
      const { selectedMapServiceId, objectId } = this;
      if (!selectedMapServiceId || !objectId) {
        this.loadingGisInfo = false;
        return;
      }
      this.gisDataValues = await db.gisDataValues.toCollection().toArray();
      const gisDataValueIds = this.gisDataValues.map(
        (g) => g.gis_data_field_id
      );
      this.gisDataFields = await db.gisDataFields
        .filter((g) => {
          return (
            g.map_service_id === selectedMapServiceId &&
            !gisDataValueIds.includes(objectId)
          );
        })
        .toArray();
      this.loadingGisInfo = false;
    },
    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    async getGisDataFieldOptions() {
      this.gisDataFieldOptions = await db.gisDataFieldOptions
        .toCollection()
        .toArray();
    },
    async loadLatestGisData() {
      this.loadingGisFields = true;
      const [results] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedMapServiceId)
        .toArray();
      try {
        const mapServiceUrl = results.service_url;
        const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
          params: {
            objectids: this.objectId,
            outFields: "*",
            f: "json",
            token: localStorage.getItem("esri_token"),
          },
        });
        const { features, fields } = data;
        const [feature] = features ?? [];
        const featureItemAttributes = feature?.attributes ?? {};
        this.latestFields = fields;
        this.latestFeatureItemAttributes = featureItemAttributes;
        this.loadingGisFields = false;
        this.latestGisDataLoaded = true;
        this.openPanels = [0, 1];
      } catch (error) {
        console.log(error);
      }
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
  },
  async beforeMount() {
    this.loadingGisFields = true;
    this.loadingGisInfo = true;
    this.onResize();
    window.addEventListener("resize", this.onResize);
    await this.getLatestUtiliSyncFields();
    this.getUtiliSyncFieldsAndValues();
    this.getMapServices();
    this.getGisDataFieldOptions();
    this.loadingGisFields = false;
  },
  watch: {
    globalId() {
      this.getUtiliSyncFieldsAndValues();
    },
    featureId() {
      this.getUtiliSyncFieldsAndValues();
    },
    selectedMapServiceId() {
      this.getUtiliSyncFieldsAndValues();
    },
    gisInfoAttributes: {
      deep: true,
      immediate: true,
      handler(val) {
        for (const [key, value] of Object.entries(val)) {
          this.$set(this.richTextField, key, this.isRichTextField(value));
        }
      },
    },
    utiliSyncFields: {
      deep: true,
      immediate: true,
      handler(val) {
        for (const { name, value } of val) {
          this.$set(
            this.richTextUtiliSyncField,
            name,
            this.isRichTextField(value)
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.gap {
  gap: 5px;
}

.ql-editor {
  margin: 0px;
  padding: 0px;
}
</style>
