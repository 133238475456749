var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { background: _vm.inRightPane } }, [
    _c(
      "section",
      {
        class: _vm.inExpandInfoDialog ? "overflow-y-auto" : undefined,
        style: _vm.contentHeight
      },
      [
        _c(
          "v-expansion-panels",
          {
            attrs: { flat: "", multiple: "" },
            model: {
              value: _vm.openPanels,
              callback: function($$v) {
                _vm.openPanels = $$v
              },
              expression: "openPanels"
            }
          },
          [
            _c(
              "v-expansion-panel",
              { class: { background: _vm.inRightPane }, attrs: { value: "0" } },
              [
                _c(
                  "v-expansion-panel-header",
                  {
                    staticClass: "text-uppercase px-5",
                    class: { background: _vm.inRightPane }
                  },
                  [_vm._v(" UtiliSync Fields ")]
                ),
                _c(
                  "v-expansion-panel-content",
                  {
                    staticClass: "py-0",
                    class: { background: _vm.inRightPane }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-end align-center px-2 py-0 my-0"
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "#3F51B5" },
                            on: { click: _vm.reload }
                          },
                          [
                            _c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))]),
                            _c("span", { staticClass: "ml-1" }, [
                              _vm._v("Reload")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.gettingLatestUtiliSyncFields
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-end align-center gap caption px-6",
                            class: { background: _vm.inRightPane }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", size: 15 }
                            }),
                            _vm._v(" Checking for Updates ")
                          ],
                          1
                        )
                      : _vm._e(),
                    [
                      _c("section", { staticClass: "px-9" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center caption" },
                          [_vm._v("Feature ID")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.objectId))])
                      ]),
                      _c("section", { staticClass: "px-9" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center caption" },
                          [_vm._v("Global ID")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalId))])
                      ]),
                      _c(
                        "section",
                        {
                          staticClass: "px-5 ma-0",
                          class: { background: _vm.inRightPane }
                        },
                        _vm._l(_vm.utiliSyncFields, function(r) {
                          return _c(
                            "div",
                            {
                              key: r.gis_data_value_id,
                              staticClass: "py-1 ma-0"
                            },
                            [
                              _c(
                                "div",
                                { class: { background: _vm.inRightPane } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                      caption\n                      d-flex\n                      align-center\n                      justify-space-between\n                      gap\n                    "
                                    },
                                    [
                                      _c(
                                        "section",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _vm.isUtiliSyncFieldsEditable(r)
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "cursor-pointer pr-1",
                                                  attrs: {
                                                    small: "",
                                                    color: "#3F51B5"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.$set(
                                                        _vm.editingUtiliSyncField,
                                                        r.name,
                                                        true
                                                      )
                                                      _vm.isEditing = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiPencil) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "cursor-pointer pr-1",
                                                  attrs: {
                                                    small: "",
                                                    disabled: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiPencil) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                          _c("div", [
                                            _vm._v(" " + _vm._s(r.name) + " ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm.editingUtiliSyncField[r.name] &&
                                      _vm.getUtiliSyncFieldType(r) ===
                                        "string" &&
                                      !_vm.isUtiliSyncFieldRefField(r)
                                        ? _c("v-switch", {
                                            attrs: { label: "Rich Text" },
                                            model: {
                                              value:
                                                _vm.richTextUtiliSyncField[
                                                  r.name
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.richTextUtiliSyncField,
                                                  r.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "richTextUtiliSyncField[r.name]"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  !_vm.editingUtiliSyncField[r.name]
                                    ? _c("div", { staticClass: "pl-4" }, [
                                        !_vm.isRichTextField(r.value)
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "word-break": "break-word"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getUtiliSyncFieldValueLabel(
                                                        r,
                                                        r.value
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c("div", {
                                              staticClass: "ql-editor",
                                              staticStyle: {
                                                "word-break": "break-word"
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(r.value)
                                              }
                                            })
                                      ])
                                    : _c("GisFieldInput", {
                                        attrs: {
                                          richTextField:
                                            _vm.richTextUtiliSyncField[r.name],
                                          fieldType: _vm.getUtiliSyncFieldType(
                                            r
                                          ),
                                          gisDataFieldOptions:
                                            r.gisDataFieldOptions
                                        },
                                        on: {
                                          "cancel-edit": function($event) {
                                            _vm.$set(
                                              _vm.editingUtiliSyncField,
                                              r.name,
                                              false
                                            )
                                            _vm.isEditing = false
                                          },
                                          input: function($event) {
                                            _vm.$set(
                                              _vm.editingUtiliSyncField,
                                              r.name,
                                              false
                                            )
                                            _vm.isEditing = false
                                            _vm.saveUtiliSyncField(
                                              r.gisDataValueId,
                                              r.name,
                                              $event,
                                              r
                                            )
                                          }
                                        },
                                        model: {
                                          value: r.value,
                                          callback: function($$v) {
                                            _vm.$set(r, "value", $$v)
                                          },
                                          expression: "r.value"
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            ),
            _vm.notUtiliSyncLayer
              ? _c(
                  "v-expansion-panel",
                  {
                    class: { background: _vm.inRightPane },
                    attrs: { value: "1" }
                  },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase nav-border-top my-0 py-0 px-5",
                        class: { background: _vm.inRightPane }
                      },
                      [_vm._v(" GIS Fields ")]
                    ),
                    _vm.showGisFields
                      ? _c(
                          "v-expansion-panel-content",
                          {
                            staticClass: "my-0 py-0",
                            class: { background: _vm.inRightPane }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-end align-center px-2 py-0 my-0"
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "#3F51B5" },
                                    on: { click: _vm.loadLatestGisData }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.mdiRefresh))
                                    ]),
                                    _c("span", { staticClass: "ml-1" }, [
                                      _vm._v("Reload")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.isOnline
                              ? [
                                  _vm.loadingGisFields
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center",
                                          class: { background: _vm.inRightPane }
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: { indeterminate: "" }
                                          })
                                        ],
                                        1
                                      )
                                    : [
                                        Object.keys(
                                          _vm.gisInfoAttributesToDisplay
                                        ).length > 0
                                          ? _c(
                                              "section",
                                              { staticClass: "px-5 ma-0" },
                                              _vm._l(
                                                _vm.gisInfoAttributesToDisplay,
                                                function(value, key) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: key,
                                                      staticClass: "py-1 ma-0"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "caption d-flex align-center justify-space-between"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex"
                                                            },
                                                            [
                                                              _vm.isGisFieldsEditable(
                                                                key
                                                              )
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer pr-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "#3F51B5"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.editingGisField,
                                                                            key,
                                                                            true
                                                                          )
                                                                          _vm.isEditing = true
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.mdiPencil
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isGisFieldsEditable(
                                                                key
                                                              )
                                                                ? _c("div", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          key
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ml-4"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            key
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          ),
                                                          _vm.editingGisField[
                                                            key
                                                          ] &&
                                                          _vm.getEsriFieldType(
                                                            key
                                                          ) ===
                                                            "esriFieldTypeString" &&
                                                          !Array.isArray(
                                                            _vm.getCodeValues(
                                                              key
                                                            )
                                                          ) &&
                                                          !_vm.isEsriFieldRefField(
                                                            key
                                                          )
                                                            ? _c("v-switch", {
                                                                attrs: {
                                                                  label:
                                                                    "Rich Text"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .richTextField[
                                                                      key
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.richTextField,
                                                                      key,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "richTextField[key]"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      !_vm.editingGisField[key]
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pl-4"
                                                            },
                                                            [
                                                              !_vm
                                                                .richTextField[
                                                                key
                                                              ]
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle: {
                                                                        "word-break":
                                                                          "break-word"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm.getEsriFieldType(
                                                                        key
                                                                      ) ===
                                                                        "esriFieldTypeDate" &&
                                                                      value !==
                                                                        null
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "formatDate"
                                                                                    )(
                                                                                      new Date(
                                                                                        value
                                                                                      )
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              !Array.isArray(
                                                                                _vm.getCodeValues(
                                                                                  key
                                                                                )
                                                                              )
                                                                                ? [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          value
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                : [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.getNameForCodeValue(
                                                                                            key,
                                                                                            value
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                            ],
                                                                            2
                                                                          )
                                                                    ]
                                                                  )
                                                                : _c("div", {
                                                                    staticStyle: {
                                                                      "word-break":
                                                                        "break-word"
                                                                    },
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        value
                                                                      )
                                                                    }
                                                                  })
                                                            ]
                                                          )
                                                        : _c(
                                                            "FeatureItemGisFieldInput",
                                                            {
                                                              attrs: {
                                                                richTextField:
                                                                  _vm
                                                                    .richTextField[
                                                                    key
                                                                  ],
                                                                fieldType: _vm.getEsriFieldType(
                                                                  key
                                                                ),
                                                                featureItemFields:
                                                                  _vm.featureItemFields,
                                                                gisInfoAttributeKey: key
                                                              },
                                                              on: {
                                                                "cancel-edit": function(
                                                                  $event
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.editingGisField,
                                                                    key,
                                                                    false
                                                                  )
                                                                  _vm.isEditing = false
                                                                },
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  _vm.isEditing = false
                                                                  _vm.saveEsriField(
                                                                    key,
                                                                    $event
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .gisInfoAttributes[
                                                                    key
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.gisInfoAttributes,
                                                                    key,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "gisInfoAttributes[key]"
                                                              }
                                                            }
                                                          )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c(
                                              "section",
                                              { staticClass: "px-5 py-3 ma-0" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "caption" },
                                                  [
                                                    _vm._v(
                                                      "No GIS fields configured"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                      ]
                                ]
                              : _c(
                                  "section",
                                  { staticClass: "px-5 py-3 ma-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "caption" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { color: "gray" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiCloudOffOutline) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          " Offline. GIS attributes are unavailable. "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                          ],
                          2
                        )
                      : _c(
                          "v-expansion-panel-content",
                          { style: { "background-color": "#fafafa" } },
                          [
                            _c(
                              "v-list",
                              { staticClass: "pa-0 ma-0" },
                              [
                                _c(
                                  "v-list-item",
                                  { staticClass: "px-5 ma-0" },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "caption" },
                                      [_vm._v(" GIS Fields Not Available ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("ApplyEditResultDialog", {
          attrs: {
            showApplyEditResultDialog: _vm.showApplyEditResultDialog,
            errorMessage: _vm.updateResultErrorMessage
          },
          on: {
            close: function($event) {
              _vm.showApplyEditResultDialog = false
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }