var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditNotificationTemplateDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _vm.notificationTemplate &&
      _vm.notificationTemplate.task_notification_template
        ? _c("validation-observer", {
            ref: "notificationTemplateForm",
            attrs: { slim: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var isFormValid = ref.valid
                    return [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.save.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "elevation-0",
                                  attrs: {
                                    dark: "",
                                    width: "100%",
                                    color: "#3F51B5"
                                  }
                                },
                                [
                                  _c("div", [_vm._v("Notification Template")]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "notification-template-dialog-close"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.mdiClose))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "pt-3",
                                  style: {
                                    height: _vm.$vuetify.breakpoint.xsOnly
                                      ? _vm.contentHeight + "px"
                                      : "65vh"
                                  },
                                  attrs: { id: "dynamic-form-card-text" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-3 py-5" },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              rules: { required: true },
                                              name: "Template Name"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    var valid = ref.valid
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Template Name *",
                                                          "hide-details": "",
                                                          "error-messages": errors,
                                                          success: valid
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .notificationTemplate
                                                              .task_notification_template_name,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.notificationTemplate,
                                                              "task_notification_template_name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                    notificationTemplate.task_notification_template_name\n                  "
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "section",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between align-center pt-2"
                                    },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("Notifications")
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "#3F51B5" },
                                          on: { click: _vm.addNotification }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v(_vm._s(_vm.mdiPlus))]
                                          ),
                                          _vm._v(" Notification ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.notificationTemplate
                                      .task_notification_template.notifications,
                                    function(notification, notificationIndex) {
                                      return _c(
                                        "v-card",
                                        {
                                          key: notification.id,
                                          staticClass: "border-radius-0 my-2"
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "py-2 px-3 d-flex justify-space-between align-center"
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  rules: { required: true },
                                                  name: "Notification Name"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        var valid = ref.valid
                                                        return [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Notification Name *",
                                                              "hide-details":
                                                                "",
                                                              "error-messages": errors,
                                                              success: valid
                                                            },
                                                            model: {
                                                              value:
                                                                notification.name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  notification,
                                                                  "name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "notification.name"
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c(
                                                "section",
                                                {
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onToggleNotification(
                                                            notification.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .showNotificationContent[
                                                              notification.id
                                                            ]
                                                              ? _vm.mdiChevronUp
                                                              : _vm.mdiChevronDown
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "px-0 mx-0",
                                                                        attrs: {
                                                                          icon:
                                                                            ""
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.mdiDotsVertical
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-no-wrap gap px-3 mx-0 cursor-pointer",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.duplicateNotification(
                                                                    notificationIndex
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.mdiContentCopy
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "Duplicate"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-no-wrap gap px-3 mx-0 cursor-pointer",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteNotification(
                                                                    notificationIndex
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.mdiDelete
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", [
                                                                _vm._v("Delete")
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _vm.showNotificationContent[
                                            notification.id
                                          ]
                                            ? _c(
                                                "v-card-text",
                                                { staticClass: "px-3 py-1" },
                                                [
                                                  _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "d-flex gap pb-2"
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "From Name"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  var valid =
                                                                    ref.valid
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "From Name",
                                                                          "hide-details":
                                                                            "",
                                                                          "error-messages": errors,
                                                                          success: valid
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            notification.from_name,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              notification,
                                                                              "from_name",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "notification.from_name"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            rules: {
                                                              email: true
                                                            },
                                                            name: "Reply To"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  var valid =
                                                                    ref.valid
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Reply To",
                                                                          "hide-details":
                                                                            "",
                                                                          "error-messages": errors,
                                                                          success: valid
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            notification.reply_to,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              notification,
                                                                              "reply_to",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "notification.reply_to"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "d-flex gap pb-2"
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Subject"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  var valid =
                                                                    ref.valid
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        ref:
                                                                          "subjectInput-" +
                                                                          notificationIndex,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          label:
                                                                            "Subject",
                                                                          "hide-details":
                                                                            "",
                                                                          "error-messages": errors,
                                                                          success: valid
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "append",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "v-menu",
                                                                                    {
                                                                                      attrs: {
                                                                                        "offset-y":
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          icon:
                                                                                                            "",
                                                                                                          small:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "#286054"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          small:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            _vm.mdiPlus
                                                                                                          )
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-list",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pa-0 ma-0"
                                                                                        },
                                                                                        _vm._l(
                                                                                          _vm.SMART_FIELD_CHOICES,
                                                                                          function(
                                                                                            field
                                                                                          ) {
                                                                                            return _c(
                                                                                              "v-list-item",
                                                                                              {
                                                                                                key:
                                                                                                  field.value,
                                                                                                staticClass:
                                                                                                  "py-1 px-4 mx-0 justify-space-between",
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.insertSubjectPlaceholder(
                                                                                                      field.value,
                                                                                                      notificationIndex
                                                                                                    )
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      field.label
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                        model: {
                                                                          value:
                                                                            notification.subject,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              notification,
                                                                              "subject",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "notification.subject"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "NotificationTemplateBodyEditor",
                                                    {
                                                      staticClass: "py-2",
                                                      attrs: {
                                                        selectedMapServiceId:
                                                          _vm.selectedMapServiceId,
                                                        objectId: _vm.objectId
                                                      },
                                                      model: {
                                                        value:
                                                          notification.body,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            notification,
                                                            "body",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "notification.body"
                                                      }
                                                    }
                                                  ),
                                                  _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "py-1 d-flex gap flex-wrap align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-medium"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Send Notification "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            rules: {
                                                              required: true,
                                                              min_value: 0
                                                            },
                                                            name:
                                                              "# of Days " +
                                                              notificationIndex
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  var valid =
                                                                    ref.valid
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "py-0 my-0",
                                                                        staticStyle: {
                                                                          width:
                                                                            "50px"
                                                                        },
                                                                        attrs: {
                                                                          "hide-details":
                                                                            "",
                                                                          "error-messages": errors,
                                                                          success: valid,
                                                                          type:
                                                                            "number",
                                                                          placeholder:
                                                                            "# of Days"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            notification
                                                                              .notification_date
                                                                              .value,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              notification.notification_date,
                                                                              "value",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "notification.notification_date.value"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-medium"
                                                        },
                                                        [_vm._v("Days(s)")]
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            rules: {
                                                              required: true
                                                            },
                                                            name:
                                                              "Before or After " +
                                                              notificationIndex
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  var valid =
                                                                    ref.valid
                                                                  return [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        staticClass:
                                                                          "py-0 my-0",
                                                                        attrs: {
                                                                          items:
                                                                            _vm.BEFORE_AFTER_CHOICES,
                                                                          "item-text":
                                                                            "label",
                                                                          "item-value":
                                                                            "value",
                                                                          "hide-details":
                                                                            "",
                                                                          "error-messages": errors,
                                                                          success: valid
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            notification
                                                                              .notification_date
                                                                              .before_or_after,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              notification.notification_date,
                                                                              "before_or_after",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "notification.notification_date.before_or_after"
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-medium"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Task Due Date"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }
                                  )
                                ],
                                2
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "d-flex justify-end pa-3" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        dark: isFormValid,
                                        color: "#3F51B5",
                                        disabled: !isFormValid
                                      }
                                    },
                                    [_vm._v(" Save ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3254379944
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }