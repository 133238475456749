<template id="logPanel">
  <div>
    <div v-if="!loadingLogEntries" class="px-3">
      <div class="d-flex pa-0 ma-0" v-if="!isOnline">
        <v-icon class="mr-2">
          {{ mdiCloudOffOutline }}
        </v-icon>
        <div class="caption">
          Offline. Displaying only forms saved on device
        </div>
      </div>

      <div class="py-0 my-0">
        <div v-if="formResultGroups.length > 0">
          <section
            :class="inExpandInfoDialog ? 'overflow-y-auto' : undefined"
            :style="contentHeight"
          >
            <div
              v-for="formResultGroup in formResultGroups"
              :key="formResultGroup.date"
            >
              <div
                class="px-4 d-flex align-center gap"
                v-if="formResultGroup.date !== 'null'"
              >
                <div class="font-weight-medium display-1 black--text">
                  {{ formResultGroup.date | formatVariableDueDate("DD") }}
                </div>
                <section>
                  <div style="font-size: 20px" class="black--text">
                    {{ formResultGroup.date | formatVariableDueDate("dddd") }}
                  </div>
                  <div class="black--text">
                    {{
                      formResultGroup.date | formatVariableDueDate("MMMM YYYY")
                    }}
                  </div>
                </section>
              </div>

              <v-timeline dense align-top class="ml-n3">
                <v-timeline-item
                  v-for="(formResult, i) of formResultGroup.groupedFormResults"
                  :key="`${formResult.form_result_id}-${formResult.type}-${i}`"
                  :color="isSubmitFinal(formResult) ? '#3F51B5' : '#00A9F4'"
                  fill-dot
                >
                  <v-avatar
                    slot="icon"
                    @click="onClipboardClick(formResult)"
                    style="cursor: pointer"
                    id="formButton"
                  >
                    <v-icon color="white" size="24">
                      {{ mdiClipboard }}
                    </v-icon>
                  </v-avatar>
                  <div>
                    <div class="caption font-weight-bold">
                      {{ formResult.updatedOn | time }}
                    </div>
                    <div class="caption">
                      {{
                        formResult.title ||
                        getFormName(formResult.computed_form_definition)
                      }}
                    </div>
                    <div>
                      <div v-if="formResult.type === 'offline'">
                        <div class="py-1">
                          <v-chip color="#e0e0e0" :disabled="true">
                            {{ isSubmitFinal(formResult) ? "Final" : "Draft" }}.
                            Saved on device.
                          </v-chip>
                        </div>
                      </div>
                      <div v-if="formResult.type === 'online'">
                        <div class="py-1">
                          <v-chip color="#e0e0e0" :disabled="true">
                            {{ isSubmitFinal(formResult) ? "Final" : "Draft" }}.
                            Saved online.
                          </v-chip>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </div>
            <div cols="12" class="text-center">
              <v-progress-circular
                indeterminate
                size="16"
                color="#3F51B5"
                v-if="loadingMoreLogEntries"
              >
              </v-progress-circular>
              <v-btn
                text
                color="#3F51B5"
                @click="showMoreEntries"
                v-if="
                  formResultGroups.length > 0 &&
                  totalResultCount > currentTotalResultCount
                "
                :disabled="loadingMoreLogEntries"
              >
                Show More
              </v-btn>
              <div v-else class="py-3" style="opacity: 0.6">
                All Form Submissions Loaded
              </div>
            </div>
          </section>
        </div>

        <v-card class="elevation-0" width="100%" v-else>
          <v-card-text class="elevation-0 d-flex justify-center items-center">
            There are no form submissions for this site yet.
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else class="d-flex items-center justify-center py-16">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>

    <UnsavedChangesDialog
      :showUnsavedChangesWarningDialog="showUnsavedChangesWarningDialog"
      @close-and-lose-changes="onCloseAndLoseChanges"
      @return-to-form="showUnsavedChangesWarningDialog = false"
    />
  </div>
</template>

<script>
import { db } from "@/mixins/utilisync-db";
import { mdiUpload, mdiClipboard, mdiCloudOffOutline, mdiClose } from "@mdi/js";
import UnsavedChangesDialog from "@/components/tickets/ticket-log/UnsavedChangesDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import moment from "moment";
import { cloneDeep } from "lodash";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Log",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    selectedGisInfoObj: Object,
    inExpandInfoDialog: Boolean,
  },
  components: {
    UnsavedChangesDialog,
  },
  mixins: [networkStatusMixin],
  data() {
    return {
      isOnline: navigator.onLine,
      formResults: [],
      mapServices: [],
      mdiUpload,
      mdiClipboard,
      mdiCloudOffOutline,
      showEditFormDialog: false,
      selectedFormResult: {},
      existingFormResultIdMap: {},
      showMore: false,
      onlineFormResults: [],
      offlineFormResults: [],
      page: 1,
      selectedPdfFileUrl: "",
      windowWidth: 0,
      windowHeight: 0,
      showUnsavedChangesWarningDialog: false,
      loadingLogEntries: false,
      loadingMoreLogEntries: false,
      mdiClose,
      totalResultCount: 0,
      skipSubmissions: 0,
    };
  },
  computed: {
    contentHeight() {
      if (this.inExpandInfoDialog) {
        return {
          height: this.$vuetify.breakpoint.mdAndUp ? "20vh" : undefined,
        };
      }
      return {};
    },
    canEdit() {
      const selectedOnlineFormResult = this.formResults.find(
        (fr) => fr.form_result_id === this.existingFormResultIdMap.formResultId
      );
      const { status } =
        selectedOnlineFormResult?.computed_form_definition?.form ?? {};
      const { canEditAfterFinal } =
        selectedOnlineFormResult?.computed_form_definition?.form
          ?.formDescription ?? {};
      const isFormResultSubmittedFinal = status === "SUBMITTED_FINAL";
      return Boolean(!isFormResultSubmittedFinal || canEditAfterFinal);
    },
    alreadySubmittedFinalOnline() {
      return this.selectedFormResult?.form?.status === "SUBMITTED_FINAL";
    },
    formResultGroups() {
      const dates = [
        ...new Set(
          this.formResults.map((fr) => {
            return moment(fr.updatedOn).format("YYYY-MM-DD");
          })
        ),
      ];
      return dates.map((date) => {
        return {
          date,
          groupedFormResults: this.formResults
            .filter((fr) => {
              return (
                moment(fr.updatedOn).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
              );
            })
            .sort((a, b) => {
              return moment(a.updatedOn).isSameOrAfter(moment(b.updatedOn));
            }),
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setDynamicFormProps"]),
    onCloseAndLoseChanges() {
      this.showUnsavedChangesWarningDialog = false;
      this.showEditFormDialog = false;
      this.refreshTickets();
    },
    onTicketEditFormCloseButtonClick(hasUnsavedChanges) {
      if (hasUnsavedChanges) {
        this.showUnsavedChangesWarningDialog = true;
      } else {
        this.showEditFormDialog = false;
      }
    },
    async onClipboardClick(formResult) {
      if (formResult.type === "offline") {
        const [offlineFormResult] = await db.formResults
          .filter((fr) => fr.form_result_id === formResult.form_result_id)
          .toArray();
        this.selectedFormResult = cloneDeep(
          offlineFormResult?.computed_form_definition
        );
      } else {
        const { data: computedFormDefinition } = await axiosWithRegularAuth.get(
          `${APIURL}/form_results/${formResult.form_result_id}`
        );
        this.selectedFormResult = computedFormDefinition;
      }
      this.existingFormResultIdMap = {
        formResultId: formResult.form_result_id,
      };
      this.selectedPdfFileUrl = formResult.pdfFileUrl;
      const {
        selectedFormResult,
        existingFormResultIdMap,
        selectedPdfFileUrl,
        globalId,
        objectId,
        selectedMapServiceId,
      } = this;
      const alreadySubmittedFinalOnline =
        selectedFormResult?.form?.status === "SUBMITTED_FINAL";
      const { canEditAfterFinal } =
        selectedFormResult?.form?.formDescription ?? {};
      const dynamicFormProps = {
        formDefinition: selectedFormResult,
        existingFormResultIdMap,
        canEdit:
          (this.isSubmitFinal(selectedFormResult) && canEditAfterFinal) ||
          !this.isSubmitFinal(selectedFormResult),
        selectedPdfFileUrl,
        globalId,
        objectId,
        selectedMapServiceId,
        alreadySubmittedFinalOnline,
      };
      this.setDynamicFormProps(dynamicFormProps);
    },
    isSubmitFinal(formResult) {
      return formResult?.payload?.status === "SUBMITTED_FINAL";
    },
    async getOfflineFormResults() {
      const formResults = await db.formResults
        .filter(
          (fr) =>
            fr?.computed_form_definition?.map_service_id ===
              this.selectedMapServiceId &&
            fr?.payload?.feature_attributes?.OBJECTID === this.objectId
        )
        .toArray();
      this.offlineFormResults = formResults;
      const formResultsWithType = formResults.map((fr) => {
        return { ...fr, type: "offline" };
      });
      return formResultsWithType;
    },
    async getOnlineFormResults(skip, take) {
      const { selectedMapServiceId, objectId, selectedGisInfoObj } = this;
      if (!selectedMapServiceId || !objectId) {
        return [];
      }
      const {
        data: { results = [], totalResultCount },
      } = await axiosWithRegularAuth.get(`${APIURL}/form_results`, {
        params: {
          map_service_id: selectedMapServiceId,
          feature_id: objectId,
          take,
          skip,
        },
      });
      this.onlineFormResults = results;
      this.totalResultCount = totalResultCount;
      const formResults = results.map((fr) => {
        const {
          form_result_id: formResultId,
          updated_on: updatedOn,
          status,
          pdf_file_url: pdfFileUrl,
          title,
        } = fr;
        return {
          form_result_id: formResultId,
          pdfFileUrl,
          payload: {
            status,
          },
          selectedGisInfoObj,
          updatedOn,
          title,
        };
      });
      const offlineFormResults = await db.formResults.toCollection().toArray();
      const offlineFormResultIds = offlineFormResults.map(
        (fr) => fr.form_result_id
      );
      const formResultsWithType = formResults
        .map((fr) => {
          return {
            ...fr,
            type: "online",
          };
        })
        .filter((fr) => {
          return (
            fr?.payload?.status === "SUBMITTED_FINAL" ||
            (!offlineFormResultIds.includes(fr.form_result_id) &&
              fr?.payload?.status !== "SUBMITTED_FINAL")
          );
        });
      return formResultsWithType;
    },
    async onNetworkStatusChange() {
      this.isOnline = navigator.onLine;
      this.refreshTickets();
    },
    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    getFormName(computedFormDefinition) {
      return computedFormDefinition?.form?.formDescription?.title;
    },
    async refreshTickets() {
      this.loadingLogEntries = true;
      if (this.isOnline) {
        const onlineFormResults = await this.getOnlineFormResults(0, 50);
        const offlineFormResults = await this.getOfflineFormResults();
        this.formResults = [...onlineFormResults, ...offlineFormResults].sort(
          (a, b) => +new Date(b.updatedOn) - +new Date(a.updatedOn)
        );
      } else {
        const offlineFormResults = await this.getOfflineFormResults();
        this.formResults = [...offlineFormResults].sort(
          (a, b) => +new Date(b.updatedOn) - +new Date(a.updatedOn)
        );
      }

      this.currentTotalResultCount = this.formResults.length;
      this.loadingLogEntries = false;
    },
    async showMoreEntries() {
      this.loadingMoreLogEntries = true;
      this.showMore = true;
      if (this.totalResultCount > 0) {
        this.skipSubmissions += 50;
        const onlineFormResults = await this.getOnlineFormResults(
          this.skipSubmissions,
          50
        );
        this.formResults = [...this.formResults, ...onlineFormResults].sort(
          (a, b) => +new Date(b.updatedOn) - +new Date(a.updatedOn)
        );
        this.currentTotalResultCount += this.formResults.length;
      }
      this.loadingMoreLogEntries = false;
    },
  },
  async beforeMount() {
    window.addEventListener("online", this.onNetworkStatusChange);
    window.addEventListener("offline", this.onNetworkStatusChange);
    await this.getMapServices();
    await this.refreshTickets();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onNetworkStatusChange);
    window.removeEventListener("offline", this.onNetworkStatusChange);
  },
};
</script>

<style scoped>
.status {
  font-size: 12px;
}

.icon {
  width: 20px;
  height: 20px;
}

.v-timeline-item__divider {
  max-width: 30px;
  width: 30px;
}

#ticket-log >>> .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}

.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 12px;
  height: 24px;
  font-weight: 500;
}

.v-chip--disabled {
  opacity: 1 !important;
}

.gap {
  gap: 15px;
}
</style>
