var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", persistent: "" },
      model: {
        value: _vm.showApplyEditResultDialog,
        callback: function($$v) {
          _vm.showApplyEditResultDialog = $$v
        },
        expression: "showApplyEditResultDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Error updating GIS field ")]),
          _c("v-card-text", { staticClass: "px-6 py-2" }, [
            _vm._v(" " + _vm._s(_vm.errorMessage))
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }