<template>
  <DynamicForm
    v-if="formSelected"
    :formDefinition="formDefinition"
    :selectedMapServiceId="selectedMapServiceId"
    :globalId="globalId"
    :objectId="objectId"
    :taskId="taskId"
    canEdit
    @ticket-edit-form-close-button-click="
      $emit('ticket-edit-form-close-button-click', $event)
    "
    @ticket-edit-form-close="$emit('ticket-edit-form-close')"
    @ticket-edit-form-submitted="$emit('ticket-edit-form-submitted')"
    @ticket-edit-form-error="$emit('ticket-edit-form-error', $event)"
    @input="formResult = $event"
  />
</template>

<script>
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import { db } from "@/mixins/utilisync-db";

export default {
  name: "TicketEditForm",
  components: {
    DynamicForm,
  },
  props: {
    formDefinitionId: String,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    taskId: String,
  },
  data() {
    return {
      formDefinition: {},
    };
  },
  computed: {
    formSelected() {
      return Boolean(this.formDefinition?.form);
    },
  },
  async beforeMount() {
    const [formDefinition] = await db.formDefinitions
      .filter((f) => f.form_definition_id === this.formDefinitionId)
      .toArray();
    this.formDefinition = formDefinition;
  },
};
</script>
