var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingTasks
        ? _c(
            "div",
            { staticClass: "d-flex justify-center align-center" },
            [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "d-flex justify-end align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "d-flex ma-0 pa-0 px-2",
                    attrs: { text: "", color: "#3F51B5" },
                    on: {
                      click: function($event) {
                        return _vm.refreshTasks()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v(" " + _vm._s(_vm.mdiRefresh) + " ")]
                    ),
                    _vm._v(" Reload ")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "d-flex ma-0 pa-0 px-2",
                    attrs: { text: "", color: "#3F51B5", id: "createTaskBtn" },
                    on: {
                      click: function($event) {
                        _vm.showAddTaskDialog = true
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]
                    ),
                    _vm._v(" Schedule Form ")
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "section",
              {
                staticClass: "overflow-y-auto",
                style: {
                  height:
                    _vm.$vuetify.breakpoint.mdAndUp && _vm.inExpandInfoDialog
                      ? _vm.tasksTabHeight + "px"
                      : undefined
                }
              },
              [
                _vm.taskGroups.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.taskGroups, function(taskGroup) {
                        return _c(
                          "div",
                          { key: taskGroup.date },
                          [
                            taskGroup.date !== "null"
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass: "ml-n3",
                                    attrs: { light: "", flat: "" }
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { "align-center": "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticStyle: {
                                                  "max-width": "95px"
                                                },
                                                attrs: { "justify-center": "" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "display-1 font-weight-medium"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatVariableDueDate"
                                                          )(taskGroup.date, "D")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "ml-n2",
                                                attrs: { column: "" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "20px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatVariableDueDate"
                                                          )(
                                                            taskGroup.date,
                                                            "dddd"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "mt-n1" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatVariableDueDate"
                                                          )(
                                                            taskGroup.date,
                                                            "MMMM YYYY"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-timeline",
                              {
                                staticClass: "ml-n3",
                                attrs: { "align-top": "", dense: "" }
                              },
                              _vm._l(taskGroup.groupedTasks, function(task) {
                                return _c(
                                  "v-timeline-item",
                                  {
                                    key: task.item_id,
                                    attrs: { color: "#00A9F4", "fill-dot": "" }
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          slot: "icon",
                                          id: task.item_type + "Btn"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.selectedTask = task
                                            _vm.openItem(task)
                                          }
                                        },
                                        slot: "icon"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "white" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.getIcon(task))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between ml-n2"
                                      },
                                      [
                                        _c("div", { staticClass: "pr-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "caption" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    task.assigned_to_name
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("div", [
                                            task.description
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold py-0 my-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          task.description
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-bold py-0 my-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " No task description set "
                                                    )
                                                  ]
                                                )
                                          ]),
                                          task.item_type === "action_item"
                                            ? _c(
                                                "div",
                                                { staticClass: "py-1" },
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        color: "#e0e0e0",
                                                        disabled: true
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getPillText(
                                                              task
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]),
                                        task.item_type === "task"
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    staticClass: "py-0 my-0",
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "py-0 my-0",
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        id:
                                                                          "taskOptionsBtn"
                                                                      }
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.mdiDotsVertical
                                                                      )
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      {
                                                        attrs: {
                                                          "min-width": "150px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-no-wrap gap cursor-pointer",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.selectedTask = task
                                                                _vm.showEditTaskDialog = true
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.mdiPencil
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Edit Task"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "py-2",
                        staticStyle: {
                          "text-align": "center",
                          color: "#444444"
                        }
                      },
                      [_vm._v(" No tasks created ")]
                    )
              ]
            ),
            _vm.showAddTaskDialog
              ? _c("AddTaskDialog", {
                  attrs: {
                    selectedMapServiceId: _vm.selectedMapServiceId,
                    showAddTaskDialog: _vm.showAddTaskDialog,
                    globalId: _vm.globalId,
                    objectId: _vm.objectId
                  },
                  on: {
                    "add-task-success": function($event) {
                      _vm.showAddTaskDialog = false
                      _vm.refreshTasks()
                    },
                    "add-task-dialog-close": function($event) {
                      _vm.showAddTaskDialog = false
                    }
                  }
                })
              : _vm._e(),
            _vm.showEditTaskDialog
              ? _c("EditTaskDialog", {
                  attrs: {
                    selectedMapServiceId: _vm.selectedMapServiceId,
                    showEditTaskDialog: _vm.showEditTaskDialog,
                    selectedTask: _vm.selectedTask,
                    globalId: _vm.globalId,
                    objectId: _vm.objectId
                  },
                  on: {
                    "edit-task-success": function($event) {
                      _vm.showEditTaskDialog = false
                      _vm.refreshTasks()
                    },
                    "edit-task-dialog-close": function($event) {
                      _vm.showEditTaskDialog = false
                    }
                  }
                })
              : _vm._e(),
            _vm.showActionItemDialog
              ? _c("ActionItemTabActionItemDialog", {
                  attrs: {
                    action: _vm.getActionItemLabel(_vm.selectedTask),
                    showActionItemDialog: _vm.showActionItemDialog,
                    selectedActionItem: _vm.fullActionItemData.data.results,
                    selectedActionItemId: _vm.selectedTask.item_id,
                    selectedMapServiceId: _vm.selectedMapServiceId,
                    objectId: _vm.objectId
                  },
                  on: {
                    "action-item-dialog-close": function($event) {
                      _vm.showActionItemDialog = false
                    },
                    "action-item-saved": function($event) {
                      return _vm.refreshTasks()
                    },
                    "action-item-response-saved": function($event) {
                      _vm.showActionItemDialog = false
                      _vm.refreshTasks()
                    },
                    "reject-action-item": function($event) {
                      _vm.showActionItemDialog = false
                      _vm.refreshTasks()
                    },
                    "accept-action-item": function($event) {
                      _vm.showActionItemDialog = false
                      _vm.refreshTasks()
                    }
                  }
                })
              : _vm._e(),
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "600",
                  close: "",
                  persistent: "",
                  fullscreen: _vm.$vuetify.breakpoint.xsOnly
                },
                model: {
                  value: _vm.showTicketEditForm,
                  callback: function($$v) {
                    _vm.showTicketEditForm = $$v
                  },
                  expression: "showTicketEditForm"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _vm.showTicketEditForm
                      ? _c("TicketEditForm", {
                          attrs: {
                            selectedMapServiceId: _vm.selectedMapServiceId,
                            formDefinitionId:
                              _vm.selectedTask.form_definition_id,
                            taskId: _vm.selectedTask.item_id,
                            globalId: _vm.globalId,
                            objectId: _vm.objectId
                          },
                          on: {
                            "ticket-edit-form-close-button-click": function(
                              $event
                            ) {
                              _vm.showTicketEditForm = false
                            },
                            "ticket-edit-form-close": function($event) {
                              _vm.showTicketEditForm = false
                              _vm.refreshTasks()
                              _vm.$emit("ticket-edit-form-close")
                            },
                            "ticket-edit-form-submitted": function($event) {
                              _vm.showTicketEditForm = false
                              _vm.refreshTasks()
                              _vm.$emit("ticket-edit-form-submitted")
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }