<template>
  <v-dialog :value="showAddTaskDialog" max-width="500px" persistent>
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Schedule Form</div>
        <v-spacer />
        <v-btn icon @click="$emit('add-task-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <form @submit.prevent="saveTask">
        <validation-observer ref="form">
          <v-card-text
            style="background-color: #fafafa; max-height: 60vh"
            class="overflow-y-auto"
          >
            <p class="caption">Scheduled Form Info</p>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Form To Be Completed"
                        rules="required"
                      >
                        <v-select
                          v-model="task.formDefId"
                          :items="formChoices"
                          hide-details="auto"
                          name="form"
                          item-value="value"
                          item-text="name"
                          :error-messages="errors"
                          :success="valid"
                          label="Form To Be Completed *"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex gap justify-space-between"
                    v-if="selectedMapService.site_enabled"
                  >
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Assign To"
                        rules="required"
                      >
                        <v-select
                          v-model="assignTo"
                          :items="ASSIGN_TO_CHOICES"
                          hide-details="auto"
                          name="assignTo"
                          item-value="value"
                          item-text="label"
                          :error-messages="errors"
                          :success="valid"
                          label="Assign To *"
                          @change="taskAssignedTo = {}"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex gap justify-space-between"
                    v-if="assignTo === ASSIGN_TO.SITE_CONTACT"
                  >
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Site Contact"
                        rules="required"
                      >
                        <v-combobox
                          v-model="taskAssignedTo"
                          :items="sharedSiteContactsChoices"
                          hide-details="auto"
                          name="siteContact"
                          item-value="value"
                          :error-messages="errors"
                          :success="valid"
                          label="Site Contact *"
                          return-object
                        >
                          <template #item="{ item }">
                            <section>
                              <div>{{ item.name }}</div>
                              <div class="caption">{{ item.email }}</div>
                            </section>
                          </template>

                          <template #selection="{ item }">
                            <section>
                              <div>{{ item.name }}</div>
                            </section>
                          </template>
                        </v-combobox>
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex gap justify-space-between"
                    v-else-if="assignTo === ASSIGN_TO.ORG_USER"
                  >
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Assigned To"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="taskAssignedTo"
                          :items="userChoices"
                          hide-details="auto"
                          name="assignedTo"
                          item-value="value"
                          item-text="name"
                          :error-messages="errors"
                          :success="valid"
                          label="Assigned To *"
                          return-object
                          class="autocomplete"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12" class="pb-0">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Due Date"
                        rules="required"
                      >
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="task.due"
                              :error-messages="errors"
                              :success="valid"
                              color="#3F51B5"
                              label="Due Date *"
                            >
                              <template #append>
                                <v-icon>
                                  {{ mdiCalendar }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="task.due"></v-date-picker>
                        </v-menu>
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Description"
                    >
                      <v-text-field
                        v-model="task.description"
                        :error-messages="errors"
                        :success="valid"
                        label="Description"
                        color="#3F51B5"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <div class="d-flex align-end">
                      <div class="half">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Notification Template"
                        >
                          <v-select
                            v-model="task.taskNotificationTemplateId"
                            :items="taskNotificationTemplateChoices"
                            hide-details="auto"
                            name="notificationTemplate"
                            item-value="value"
                            item-text="name"
                            :error-messages="errors"
                            :success="valid"
                            label="Notification Template"
                            clearable
                            :disabled="!isOnline"
                            class="py-0"
                          >
                            <template v-slot:prepend-item>
                              <v-divider></v-divider>
                              <v-list-item
                                class="py-0"
                                @click="showNotificationTemplateDialog = true"
                              >
                                <div
                                  style="color: #3f51b5"
                                  class="addTemplateButton"
                                >
                                  <v-icon color="#3f51b5" small>{{
                                    mdiPlus
                                  }}</v-icon>
                                  Notification Template
                                </div>
                              </v-list-item>
                              <v-divider></v-divider>
                            </template>
                          </v-select>
                        </validation-provider>
                      </div>

                      <v-menu
                        offset-y
                        v-if="task.taskNotificationTemplateId && isGisAdmin"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>{{ mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list class="px-0 mx-0">
                          <v-list-item
                            class="
                              d-flex
                              flex-no-wrap
                              gap
                              px-3
                              mx-0
                              cursor-pointer
                            "
                            @click="editSelectedNotificationTemplate"
                          >
                            <div>
                              <v-icon>
                                {{ mdiPencil }}
                              </v-icon>
                            </div>
                            <div>Edit</div>
                          </v-list-item>
                          <v-list-item
                            class="
                              d-flex
                              flex-no-wrap
                              gap
                              px-3
                              mx-0
                              cursor-pointer
                            "
                            @click="deleteSelectedNotificationTemplate"
                          >
                            <div>
                              <v-icon>
                                {{ mdiDelete }}
                              </v-icon>
                            </div>
                            <div>Delete</div>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <NotificationTemplateDialog
              v-if="showNotificationTemplateDialog"
              :showNotificationTemplateDialog="showNotificationTemplateDialog"
              :assignTo="assignTo"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
              @notification-template-dialog-close="
                showNotificationTemplateDialog = false
              "
              @notification-template-saved="
                showNotificationTemplateDialog = false;
                getTaskNotificationTemplates();
              "
            />

            <EditNotificationTemplateDialog
              v-if="showEditNotificationTemplateDialog"
              :selectedNotificationTemplate="selectedNotificationTemplate"
              :assignTo="assignTo"
              :showEditNotificationTemplateDialog="
                showEditNotificationTemplateDialog
              "
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
              @notification-template-dialog-close="
                showEditNotificationTemplateDialog = false
              "
              @notification-template-saved="
                showEditNotificationTemplateDialog = false;
                getTaskNotificationTemplates();
              "
            />
          </v-card-text>
          <v-card-actions class="d-flex justify-end py-3 px-4">
            <v-btn type="submit" color="#3F51B5" dark>Schedule</v-btn>
          </v-card-actions>
        </validation-observer>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiCalendar,
  mdiPencil,
  mdiDotsVertical,
  mdiPlus,
  mdiDelete,
} from "@mdi/js";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import {
  ASSIGN_TO,
  ASSIGN_TO_CHOICES,
  MANUALLY_ADD_EMAIL,
} from "@/constants/actionItemChoices";
import NotificationTemplateDialog from "@/components/mapView/tasks-tab/shared/NotificationTemplateDialog";
import EditNotificationTemplateDialog from "@/components/mapView/tasks-tab/shared/EditNotificationTemplateDialog";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import moment from "moment";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddTaskDialog",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    showAddTaskDialog: Boolean,
  },
  components: {
    NotificationTemplateDialog,
    EditNotificationTemplateDialog,
  },
  mixins: [bulkDownloadDataMixin],
  computed: {
    sharedSiteContactsChoices() {
      return this.sharedSiteContacts.map((c) => {
        const { email, f_name: fName, l_name: lName, user_id: value } = c;
        return {
          name: `${fName} ${lName}`,
          email,
          value,
        };
      });
    },
    userChoices() {
      return this.users
        .filter((u) => u.is_active && !u.is_contact)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            name: `${fName} ${lName}`,
            value,
          };
        });
    },
    formChoices() {
      return this.forms.map((f) => {
        const { title, form_definition_id: formDefId } = f;
        return {
          name: title,
          value: formDefId,
        };
      });
    },
    taskNotificationTemplateChoices() {
      return this.taskNotificationTemplates
        .map((t) => {
          const {
            task_notification_template_id: tId,
            task_notification_template_name: name,
          } = t;
          return {
            name,
            value: tId,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    taskTypeChoices() {
      return this.taskTypes.map((t) => {
        const { name, task_type_id: value } = t;
        return { name, value };
      });
    },
    isGisAdmin() {
      const user = JSON.parse(localStorage.getItem("auth"));
      return user.is_gis_admin;
    },
  },
  data() {
    return {
      mdiClose,
      mdiCalendar,
      mdiPencil,
      mdiDotsVertical,
      mdiPlus,
      mdiDelete,
      task: {
        formDefId: "",
        assignedTo: "",
        description: "",
        due: "",
        taskNotificationTemplateId: "",
      },
      users: [],
      forms: [],
      taskNotificationTemplates: [],
      taskTypes: [],
      isOnline: navigator.onLine,
      selectedMapService: {},
      ASSIGN_TO,
      ASSIGN_TO_CHOICES,
      MANUALLY_ADD_EMAIL,
      assignTo: undefined,
      sharedSiteContacts: [],
      sharedSiteContactEmail: "",
      taskAssignedTo: {},
      showNotificationTemplateDialog: false,
      showEditNotificationTemplateDialog: false,
      selectedNotificationTemplate: {},
    };
  },
  methods: {
    async editSelectedNotificationTemplate() {
      const taskNotificationTemplate = this.taskNotificationTemplates.find(
        (t) =>
          t.task_notification_template_id ===
          this.task.taskNotificationTemplateId
      );
      this.selectedNotificationTemplate = taskNotificationTemplate;
      this.showEditNotificationTemplateDialog = true;
    },
    async deleteSelectedNotificationTemplate() {
      await axiosWithRegularAuth.delete(
        `${APIURL}/task_notification_template/${this.task.taskNotificationTemplateId}`
      );
      await this.getTaskNotificationTemplates();
    },
    async getSiteContacts() {
      const { selectedMapServiceId, objectId } = this;
      if (!selectedMapServiceId || isNaN(objectId)) {
        return;
      }

      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/shared_site_contacts`, {
        params: {
          map_service_id: selectedMapServiceId,
          feature_id: objectId,
        },
      });
      this.sharedSiteContacts = results;
    },
    async getMapService() {
      const [mapService] = await db.mapServices
        .filter((m) => {
          return m.map_service_id === this.selectedMapServiceId;
        })
        .toArray();
      this.selectedMapService = mapService;
    },
    async getFeatureAttributes() {
      const { selectedMapServiceId, objectId } = this;
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();
      if (selectedMapService?.service_type === "F") {
        let params;
        if (selectedMapService?.token_type === "NONE") {
          params = {
            objectids: objectId,
            outFields: "*",
            f: "json",
          };
        } else {
          params = {
            objectids: objectId,
            outFields: "*",
            f: "json",
            token: localStorage.getItem("esri_token"),
          };
        }
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${selectedMapService.service_url}/query`,
          {
            params,
          }
        );
        const [feature] = queryResult?.features ?? [];
        const featureAttributes = {
          ...feature?.attributes,
        };
        return featureAttributes;
      } else if (selectedMapService?.service_type === "U") {
        const mapServices = await db.mapServices.toCollection().toArray();
        const gisDataValues = await db.gisDataValues.toCollection().toArray();
        const allGisDataFields = await db.gisDataFields
          .toCollection()
          .toArray();
        const gisDataValueIds =
          gisDataValues?.map((g) => g.gis_data_field_id) ?? [];
        const gisDataFields = allGisDataFields.filter((g) => {
          return (
            g.map_service_id === selectedMapServiceId &&
            !gisDataValueIds.includes(objectId)
          );
        });

        const gisDataFieldsAndValues = gisDataFields?.map((gdf) => {
          const { name } = gdf;
          const value = gisDataValues?.find((gdv) => {
            return (
              gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
              gdv?.feature_id === objectId
            );
          })?.value;

          return {
            name,
            value,
          };
        });
        const mapService = mapServices.find(
          (m) => m.map_service_id === selectedMapServiceId
        );
        const { ref_field: refField } = mapService ?? {};
        const refFieldValue = gisDataFieldsAndValues?.find(
          (f) => f.name === refField
        )?.value;
        const refFieldObj = refField ? { [refField]: refFieldValue } : {};
        const [gisDataPoint] = await db.gisDataPoints
          .filter(
            (gdp) =>
              gdp.map_service_id === selectedMapServiceId &&
              gdp.object_id === objectId
          )
          .toArray();
        const featureAttributes = {
          OBJECTID: objectId,
          gisDataPointId: gisDataPoint?.gis_data_point_id,
          ...refFieldObj,
        };
        return featureAttributes;
      }
    },
    async saveTask() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      const { taskAssignedTo, selectedMapServiceId, globalId, objectId } = this;

      let assignedTo;
      let siteId;
      if (this.assignTo === ASSIGN_TO.SITE_CONTACT) {
        assignedTo = taskAssignedTo?.value;
        siteId = this.sharedSiteContacts.find(
          (c) => c.user_id === taskAssignedTo?.value
        )?.site_id;
      } else {
        assignedTo = taskAssignedTo?.value;
      }

      const { description, due, taskNotificationTemplateId, formDefId } =
        this.task;

      const payload = {
        map_service_id: selectedMapServiceId,
        feature_id: objectId,
        description,
        assigned_to: assignedTo,
        due: moment.utc(due).format("YYYY-MM-DD"),
        assignee_closable: true,
        task_notification_template_id: taskNotificationTemplateId
          ? taskNotificationTemplateId
          : undefined,
        task_type_id: this.taskTypeChoices.find(
          (tt) => tt.name === "User Created Task"
        )?.value,
        form_definition_id: formDefId,
        globalId,
        status: "Open",
        site_id: siteId,
        featureAttributes: JSON.stringify(await this.getFeatureAttributes()),
        app_enqueued_from: "FIELD_REPORTS",
      };
      const {
        data: { result },
      } = await axiosWithRegularAuth.post(`${APIURL}/tasks`, payload);
      await db.tasks.put(result);
      this.$emit("add-task-success");
    },
    async getUsers() {
      this.users = await db.users.toCollection().toArray();
    },
    async getTaskNotificationTemplates() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/task_notification_templates`,
        {}
      );
      this.taskNotificationTemplates = results;
    },
    async getForms() {
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedMapServiceId)
        .toArray();
      this.forms = mapService?.forms ?? [];
    },
    async getTaskTypes() {
      this.taskTypes = await db.taskTypes.toCollection().toArray();
    },
    onNetworkStatusChange() {
      this.isOnline = navigator.onLine;
    },
  },
  async beforeMount() {
    await this.bulkDownloadUsers();
    await this.getUsers();
    await this.getTaskNotificationTemplates();
    await this.getForms();
    await this.getTaskTypes();
    await this.getMapService();
    await this.getSiteContacts();
    if (this.selectedMapService.site_enabled) {
      this.assignTo = ASSIGN_TO.SITE_CONTACT;
    } else {
      this.assignTo = ASSIGN_TO.ORG_USER;
    }
    window.addEventListener("online", this.onNetworkStatusChange);
    window.addEventListener("offline", this.onNetworkStatusChange);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onNetworkStatusChange);
    window.removeEventListener("offline", this.onNetworkStatusChange);
  },
};
</script>

<style scoped>
.half {
  width: 50%;
}

.addTemplateButton {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
}
</style>
