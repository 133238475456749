var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-observer", { ref: "fieldValueForm" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.save.apply(null, arguments)
          }
        }
      },
      [
        _c("validation-provider", {
          attrs: { name: "Value" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                var valid = ref.valid
                return [
                  _vm.fieldType === "string"
                    ? [
                        _vm.gisDataFieldOptions.length === 0
                          ? [
                              _vm.richTextField
                                ? _c("QuillEditor", {
                                    attrs: {
                                      height: "120px",
                                      showExtraButtons: false
                                    },
                                    model: {
                                      value: _vm.inputValue,
                                      callback: function($$v) {
                                        _vm.inputValue = $$v
                                      },
                                      expression: "inputValue"
                                    }
                                  })
                                : _c("v-text-field", {
                                    staticClass: "py-0 my-0",
                                    attrs: {
                                      "error-messages": errors,
                                      success: valid,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.inputValue,
                                      callback: function($$v) {
                                        _vm.inputValue = $$v
                                      },
                                      expression: "inputValue"
                                    }
                                  })
                            ]
                          : _c(
                              "div",
                              { staticClass: "select" },
                              [
                                _c("v-select", {
                                  staticClass: "py-0 my-0",
                                  staticStyle: { "z-index": "103" },
                                  attrs: {
                                    items: _vm.mappedGisDataFieldOptions,
                                    "item-text": "label",
                                    "item-value": "value",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.inputValue,
                                    callback: function($$v) {
                                      _vm.inputValue = $$v
                                    },
                                    expression: "inputValue"
                                  }
                                })
                              ],
                              1
                            )
                      ]
                    : _vm.fieldType === "number"
                    ? _c("v-text-field", {
                        staticClass: "py-0 my-0",
                        attrs: {
                          "error-messages": errors,
                          success: valid,
                          "hide-details": "",
                          type: "number"
                        },
                        on: { keydown: _vm.onNumberKeyDown },
                        model: {
                          value: _vm.inputValue,
                          callback: function($$v) {
                            _vm.inputValue = _vm._n($$v)
                          },
                          expression: "inputValue"
                        }
                      })
                    : _vm.fieldType === "date"
                    ? _c(
                        "v-menu",
                        {
                          staticStyle: { "z-index": "100" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "validation-provider",
                                    { attrs: { rules: { required: true } } },
                                    [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              value: _vm.selectedDate,
                                              "hide-details": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#3F51B5"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiCalendar
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          on
                                        )
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("v-date-picker", {
                            staticStyle: { "z-index": "100" },
                            model: {
                              value: _vm.inputValue,
                              callback: function($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        }),
        _c(
          "section",
          { staticClass: "d-flex justify-end py-2" },
          [
            _c(
              "v-btn",
              {
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("cancel-edit")
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "v-btn",
              { attrs: { dark: "", color: "#3F51B5", type: "submit" } },
              [_vm._v("Save")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }