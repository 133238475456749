var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showUrlEditorDialog, "max-width": "300px" } },
    [
      _c(
        "v-card",
        [
          _c("validation-observer", {
            ref: "urlEditorForm",
            attrs: { slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var isFormValid = ref.valid
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-3" },
                          [
                            _c("validation-provider", {
                              attrs: { rules: { required: true }, name: "URL" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "URL",
                                            "hide-details": "",
                                            "error-messages": errors,
                                            success: valid
                                          },
                                          model: {
                                            value: _vm.url,
                                            callback: function($$v) {
                                              _vm.url = $$v
                                            },
                                            expression: "url"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end pa-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  type: "submit",
                                  disabled: !isFormValid
                                }
                              },
                              [_vm._v("Save")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }