var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-1", class: { background: _vm.inRightPane } },
    [
      _c(
        "section",
        { staticClass: "px-7 py-3 d-flex justify-space-between align-center" },
        [
          _c("div", { class: { background: _vm.inRightPane } }, [
            _vm.mapServicesOfNonLocateRequestChoices.length > 1
              ? _c(
                  "section",
                  [
                    _c("v-select", {
                      style: {
                        "max-width":
                          _vm.$vuetify.breakpoint.mdAndUp &&
                          _vm.inExpandInfoDialog
                            ? undefined
                            : "200px",
                        width:
                          _vm.$vuetify.breakpoint.mdAndUp &&
                          _vm.inExpandInfoDialog
                            ? undefined
                            : "200px"
                      },
                      attrs: {
                        label: "Layer",
                        items: _vm.mapServicesOfNonLocateRequestChoices,
                        "item-value": "value",
                        "item-text": "name",
                        name: "layer"
                      },
                      on: {
                        change: function($event) {
                          return _vm.$emit("layer-selected", _vm.selectedLayer)
                        }
                      },
                      model: {
                        value: _vm.selectedLayer,
                        callback: function($$v) {
                          _vm.selectedLayer = $$v
                        },
                        expression: "selectedLayer"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "section",
              [
                _c("v-select", {
                  style: {
                    "max-width":
                      _vm.$vuetify.breakpoint.mdAndUp && _vm.inExpandInfoDialog
                        ? undefined
                        : "200px",
                    width:
                      _vm.$vuetify.breakpoint.mdAndUp && _vm.inExpandInfoDialog
                        ? undefined
                        : "200px"
                  },
                  attrs: {
                    items: _vm.formDefinitionsOfSelectedMapServiceChoices,
                    label: "Report Form",
                    "hide-details": "auto",
                    name: "formDef",
                    "item-value": "value",
                    "item-text": "name",
                    disabled: !_vm.selectedLayer,
                    id: "formDropdown"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "px-5 py-3" }, [
                            _vm._v(
                              " No report forms configured for this layer "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("div", { staticClass: "drop-down-selection" }, [
                            _vm._v(" " + _vm._s(item.name) + " ")
                          ])
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.formDefinitionId,
                    callback: function($$v) {
                      _vm.formDefinitionId = $$v
                    },
                    expression: "formDefinitionId"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "pl-4", class: { background: _vm.inRightPane } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "#3F51B5",
                    disabled: !_vm.formDefinitionId,
                    dark: Boolean(_vm.formDefinitionId),
                    fab: "",
                    small: "",
                    id: "startFormBtn"
                  },
                  on: { click: _vm.onFabClick }
                },
                [
                  _c("v-icon", [
                    _vm._v(" " + _vm._s(_vm.mdiNotePlusOutline) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600",
            close: "",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.showEditDialog,
            callback: function($$v) {
              _vm.showEditDialog = $$v
            },
            expression: "showEditDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.showEditDialog
                ? _c("TicketEditForm", {
                    attrs: {
                      selectedGisInfo: _vm.selectedGisInfoObj,
                      selectedMapServiceId: _vm.selectedMapServiceId,
                      formDefinitionId: _vm.formDefinitionId,
                      globalId: _vm.globalId,
                      objectId: _vm.objectId
                    },
                    on: {
                      "ticket-edit-form-close-button-click":
                        _vm.onTicketEditFormCloseButtonClick,
                      "ticket-edit-form-close": function($event) {
                        _vm.showEditDialog = false
                        _vm.$emit("ticket-edit-form-close")
                      },
                      "ticket-edit-form-submitted": function($event) {
                        _vm.showEditDialog = false
                        _vm.$emit("ticket-edit-form-submitted")
                      },
                      "ticket-edit-form-error": function($event) {
                        _vm.showErrorDialog = true
                        _vm.errMsg = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", close: "" },
          model: {
            value: _vm.showErrorDialog,
            callback: function($$v) {
              _vm.showErrorDialog = $$v
            },
            expression: "showErrorDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Error ")]),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.errMsg) + " ")])
            ],
            1
          )
        ],
        1
      ),
      _c("ViewOnlyUserDialog", {
        attrs: { showViewOnlyUserDialog: _vm.showViewOnlyUserDialog },
        on: {
          "view-only-dialog-close": function($event) {
            _vm.showViewOnlyUserDialog = false
          }
        }
      }),
      _c("UnsavedChangesDialog", {
        attrs: {
          showUnsavedChangesWarningDialog: _vm.showUnsavedChangesWarningDialog
        },
        on: {
          "close-and-lose-changes": _vm.onCloseAndLoseChanges,
          "return-to-form": function($event) {
            _vm.showUnsavedChangesWarningDialog = false
          }
        }
      }),
      _vm.showChooseTaskDialog
        ? _c("ChooseTaskDialog", {
            attrs: {
              showChooseTaskDialog: _vm.showChooseTaskDialog,
              selectedMapServiceId: _vm.selectedMapServiceId,
              objectId: _vm.objectId,
              globalId: _vm.globalId,
              formDefinitionId: _vm.formDefinitionId
            },
            on: {
              "choose-task-dialog-close": function($event) {
                _vm.showChooseTaskDialog = false
              },
              "task-selected": _vm.onTaskSelected,
              "start-new-form": _vm.onStartNewForm
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }