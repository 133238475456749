var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showAddFilesDialog, width: "600px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Add Files")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-files-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c("p", { staticClass: "caption" }, [
                _vm._v("Folder to Save Files To")
              ]),
              _c("FolderPathDisplay", {
                staticClass: "py-2",
                attrs: {
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  globalId: _vm.globalId,
                  objectId: _vm.objectId,
                  selectedFolder: _vm.selectedFolder
                },
                on: {
                  "select-folder": function($event) {
                    _vm.selectedFolder = $event
                  }
                }
              }),
              _c("p", { staticClass: "caption" }, [_vm._v("Photos")]),
              _c("file-pond", {
                ref: "photos",
                attrs: {
                  name: "photos",
                  "label-idle": "Tap or drop photos here...",
                  "allow-multiple": "",
                  files: _vm.uploadFiles,
                  allowFileSizeValidation: "",
                  maxFileSize: "2MB",
                  "accepted-file-types": "image/jpeg, image/png"
                },
                on: { addfile: _vm.onPhotoChange }
              }),
              _c("p", { staticClass: "caption" }, [_vm._v("Files")]),
              _c("file-pond", {
                ref: "files",
                attrs: {
                  name: "files",
                  "label-idle": "Tap or drop files here...",
                  "allow-multiple": "",
                  files: _vm.uploadFiles,
                  allowFileSizeValidation: "",
                  maxFileSize: "2MB"
                },
                on: { addfile: _vm.onFileChange }
              }),
              _vm.showFileDescriptionDialog
                ? _c("FileDescriptionDialog", {
                    attrs: {
                      showFileDescriptionDialog: _vm.showFileDescriptionDialog,
                      uploadedFiles: _vm.uploadedPhotoFiles.concat(
                        _vm.uploadedFiles
                      )
                    },
                    on: {
                      "file-description-dialog-close": function($event) {
                        _vm.showFileDescriptionDialog = false
                      },
                      "description-saved": function($event) {
                        _vm.showFileDescriptionDialog = false
                        _vm.fileDescriptions = $event
                        _vm.uploadFile()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }