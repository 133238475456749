<template>
  <v-dialog :value="showPdfViewerDialog" fullscreen>
    <v-card height="100vh" class="d-flex flex-column">
      <v-toolbar
        dark
        class="elevation-0 flex-grow-0"
        width="100%"
        color="#3F51B5"
        height="56px"
      >
        <div>View PDF</div>
        <v-spacer />
        <v-btn icon @click="$emit('view-pdf-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-0 ma-0 d-flex flex-column flex-grow-1">
        <div class="d-flex flex-grow-1">
          <PdfViewer
            v-if="showPdfViewerDialog"
            :src="generatedPdfUrl"
            class="flex-grow-1"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import PdfViewer from "@/components/shared/PdfViewer";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PdfViewerDialog",
  props: {
    showPdfViewerDialog: Boolean,
    selectedFile: Object,
  },
  components: { PdfViewer },
  data() {
    return {
      mdiClose,
      generatedPdfUrl: undefined,
    };
  },
  methods: {
    async getFile() {
      const { data } = await axiosWithRegularAuth.get(
        `${APIURL}/files/${this.selectedFile.file_id}`,
        {
          responseType: "blob",
        }
      );
      this.generatedPdfUrl = window.URL.createObjectURL(data);
    },
  },
  beforeMount() {
    this.getFile();
  },
};
</script>
